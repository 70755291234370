import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Directive({
  selector: '[sharedGridRowResize]',
})
export class GridRowResizeDirective implements AfterViewInit, OnDestroy {
  // row element
  el?: ElementRef;
  // resize observer
  resizeObserver?: ResizeObserver;

  @Input() spacePx = 0;
  @Input() params?: ICellRendererParams;
  @Input() timeout = 100;

  constructor(el: ElementRef) {
    this.el = el;
  }

  ngAfterViewInit() {
    this.initObserver();
  }

  // adjust grid container height
  adjustHeight(elHeight: number): void {
    if (elHeight !== undefined && this.params) {
      setTimeout(() => {
        // Adjust height of the container
        this.params?.node.setRowHeight(elHeight + this.spacePx);
        this.params?.api.onRowHeightChanged();
      }, this.timeout);
    }
  }

  // responsive to screen size changes
  initObserver() {
    this.resizeObserver = new ResizeObserver(() => {
      const elHeight = this.el?.nativeElement.offsetHeight;
      this.adjustHeight(elHeight);
    });

    // Element for which to observe height and width
    this.resizeObserver.observe(this.el?.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
    this.resizeObserver?.unobserve(this.el?.nativeElement);
    this.resizeObserver = undefined;
  }
}
