import { Pipe, PipeTransform } from '@angular/core';
import { SharedConstant } from '@one-access/shared/util';
import dayjs from 'dayjs';
import { ILanguageService } from '../../../services';

/**
 * Formats a user input date value into French or English based on locale.
 */
@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private languageService: ILanguageService) {}

  transform(value: string): string {
    const locale = this.languageService.current;
    //The code below allows full control on how we want to display the french date e.g. 20. Aout.
    const date = dayjs(value);
    if (locale?.startsWith('fr') === true) {
      const year = date.get('year');
      const dayNumber = date.get('date');
      const month = SharedConstant.FRENCH_MONTHS_SHORT[date.get('month')];
      const frenchDate = `${dayNumber < 10 ? '0' : ''}${dayNumber} ${month} ${year}`;
      return frenchDate;
    } else {
      const englishDate = date.format(SharedConstant.DATE_FORMAT);
      return englishDate;
    }
  }
}
