import { SharedClientRouteURI } from '@one-access/shared/api';
import { ClientRoutePath } from './client-route-path';

export class ClientRouteURI extends SharedClientRouteURI {
  static readonly Help = `/${ClientRoutePath.Help}`;
  static readonly Register = `/${ClientRoutePath.Register}`;
  static readonly User = `/${ClientRoutePath.User}`;
  static readonly Launch = `/${ClientRoutePath.Launch}`;
  static readonly Apps = `/${ClientRoutePath.Apps}`;
  static readonly Patient = {
    Search: {
      Root: `/${ClientRoutePath.Patient.Root}/${ClientRoutePath.Patient.Search.Root}`,
      Result: `/${ClientRoutePath.Patient.Root}/${ClientRoutePath.Patient.Search.Root}/${ClientRoutePath.Patient.Search.Result}`,
    },
    Close: `/${ClientRoutePath.Patient.Root}/${ClientRoutePath.Patient.Close}`,
  };
  static readonly Redirect = `/${ClientRoutePath.Redirect}`;
}
