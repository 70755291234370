import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PcoiModalContentComponent } from './pcoi-modal-content.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PcoiModalComponent } from './pcoi-modal.component';
import { SharedPipesModule } from '../pipes';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild({ extend: true }), AccordionModule.forRoot(), SharedPipesModule],
  declarations: [PcoiModalComponent, PcoiModalContentComponent],
  exports: [PcoiModalComponent],
})
export class SharedPcoiModalModule {}
