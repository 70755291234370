import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUIUtil } from '../helpers';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FeedbackModalComponent } from './header/feedback-modal/feedback-modal.component';
import { RedirectService } from '../services/redirect/redirect.service';
import { PaginationComponent } from './pagination/pagination.component';
import { IRedirectService } from '../services';
import { AppSpinnerComponent } from './app-spinner/app-spinner.component';
import { SharedAppSpinnerComponent } from './app-spinner/shared-app-spinner.component';

/**
 * Module to initialize common controls required to initialize page layout.
 */
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FeedbackModalComponent,
    AppSpinnerComponent,
    PaginationComponent,
    SharedAppSpinnerComponent,
  ],
  imports: [CommonModule, TranslateModule.forChild({ extend: true }), RouterModule.forChild([]), BsDropdownModule],
  exports: [
    HeaderComponent,
    FooterComponent,
    TranslateModule,
    FeedbackModalComponent,
    BsDropdownModule,
    PaginationComponent,
    AppSpinnerComponent,
    SharedAppSpinnerComponent,
  ],
  providers: [SharedUIUtil, { provide: IRedirectService, useClass: RedirectService }],
})
export class PageModule {}
