import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedHeartbeatBroadcastComponent } from './heartbeat-broadcast.component';

/**
 * Exposes functionality required to broadcast heartbeat.
 */
@NgModule({
  declarations: [SharedHeartbeatBroadcastComponent],
  imports: [CommonModule],
  exports: [SharedHeartbeatBroadcastComponent],
})
export class SharedHeartbeatModule {}
