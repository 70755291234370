<div class="ow-modal">
  <div class="modal-header">
    <h2 class="modal-title pull-left">
      {{ 'sessionModal.page.textSessionExpiringSoon' | translate }}
    </h2>
  </div>

  <div class="modal-body">
    <p>
      {{ 'sessionModal.page.textYouWillBeLoggedOut' | translate }}

      <br />
      <br />
      <span class="text-center">
        <h1>{{ (timerData$ | async)?.min }}:{{ (timerData$ | async)?.sec | number: '2.0' }}</h1>
      </span>
    </p>
  </div>

  <div class="modal-footer justify-content-center justify-content-md-end border-top-0">
    <button (click)="extendSessionSelected()" class="btn btn-primary ml-4" type="button" id="btnReminderExtendSession">
      {{ 'sessionModal.page.textButtonExtendSession' | translate }}
    </button>

    <button (click)="signOutSelected()" class="btn btn-outline-primary ml-4" type="button" id="btnReminderSignOut">
      {{ 'sessionModal.page.textSignOut' | translate }}
    </button>
  </div>
</div>
