import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ILanguageService,
  IRuntimeEnvironmentService,
  LanguageService,
  runtimeEnvironmentFactory,
  RuntimeEnvironmentService,
  translateBrowserLoaderFactory,
} from '@one-access/shared/client';
import { IRuntimeEnvironment } from '@one-access/launcher/api';
import { environment } from '../environments/environment';
import { APP_VERSION } from '../version';

/**
 * Initialize runtime environment service by downloading /assets/runtime-environment.json file.
 * @param runtimeEnvironmentService
 * @returns Factory instance of runtime environment service.
 */
function initRuntimeEnvironmentFactory(runtimeEnvironmentService: IRuntimeEnvironmentService): () => Promise<void> {
  return runtimeEnvironmentFactory<IRuntimeEnvironment>(runtimeEnvironmentService, APP_VERSION);
}

/**
 * Initializes the entire Angular application. Declares AppComponent as the starting point for the application.
 * Imports all the necessary global modules and initializes them.
 */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    Title,
    Meta,
    provideHttpClient(withInterceptorsFromDi()),
    { provide: IRuntimeEnvironmentService, useClass: RuntimeEnvironmentService, deps: [HttpClient] },
    {
      provide: APP_INITIALIZER,
      useFactory: initRuntimeEnvironmentFactory,
      deps: [IRuntimeEnvironmentService],
      multi: true,
    },
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: ILanguageService,
      useClass: LanguageService,
      deps: [HttpClient, IRuntimeEnvironmentService, TranslateService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
