import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IModalService, IRedirectService } from '../../services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedUIUtil } from '../../helpers';

/**
 * Component that enables user’s session expired modal to display and blocks the user from further interacting with the
 * UI (User Interface). Users can only sign out and return to the home page.
 */
@Component({
  selector: 'shared-session-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionExpiredComponent {
  constructor(
    private bsModalRef: BsModalRef,
    private modalService: IModalService,
    private redirect: IRedirectService,
    private sharedUIUtil: SharedUIUtil
  ) {}

  signOut() {
    this.modalService.hideById(this.bsModalRef.id);
    this.redirect.navigate(this.sharedUIUtil.signOutUrl, { target: '_self' });
  }
}
