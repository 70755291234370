import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hcn',
})
export class HealthCardNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    return value
      .replace(/\s+/g, '')
      .replace(/(\d{4})(\d{3})(\d{3})/g, '$1-$2-$3')
      .trim();
  }
}
