import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IAppSpinnerService, IAppSpinnerState } from '../../services';

/**
 * Component that displays spinner.
 */
@Component({
  selector: 'shared-app-spinner-new',
  templateUrl: './shared-app-spinner.component.html',
  styleUrls: ['./shared-app-spinner.component.scss'],
})
export class SharedAppSpinnerComponent implements OnInit, OnDestroy {
  @Input() message: string;

  private spinnerServiceSub?: Subscription;
  spinner!: IAppSpinnerState;
  protected isVisible$ = new BehaviorSubject<boolean>(false);

  constructor(private spinnerService: IAppSpinnerService) {
    // Set defaults
    this.message = 'Loading ...';
  }

  ngOnInit(): void {
    // Subscribe to spinner service events
    this.spinnerServiceSub = this.spinnerService.spinnerState$.subscribe((spinnerState: IAppSpinnerState) => {
      // Use message from service, otherwise default
      this.isVisible$.next(spinnerState.isVisible);
      spinnerState.message = spinnerState.message ?? this.message;
      this.spinner = spinnerState;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from spinner service events
    if (this.spinnerServiceSub) this.spinnerServiceSub.unsubscribe();
  }
}
