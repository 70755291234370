<div>
  <div class="modal-header">
    <h5 class="modal-title pull-left" [innerHTML]="'shared.header.textFeedbackModalTitle' | translate"></h5>
    <button
      (click)="hideModal()"
      aria-label="Close"
      class="btn-close close pull-right"
      type="button"
      id="btnFeedbackClose">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p [innerHTML]="'shared.header.textFeedbackModal1' | translate"></p>

    <p [innerHTML]="'shared.header.textFeedbackModal2' | translate"></p>
  </div>

  <div class="modal-footer border-top-0 gap-3">
    <button
      (click)="openFeedback()"
      class="order-first order-md-last btn btn-primary ml-4"
      type="button"
      id="btnFeedbackOpen">
      {{ 'shared.header.open' | translate }}
    </button>
    <button (click)="hideModal()" class="btn btn-outline-primary ml-4" type="button" id="btnFeedbackCancel">
      {{ 'shared.header.cancel' | translate }}
    </button>
  </div>
</div>
