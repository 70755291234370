import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAppSpinnerService } from '../services';
import { Observable, finalize } from 'rxjs';
import { BusyHttpInterceptor } from './busy-http.interceptor';

// Show the spinner when processing an HTTP request
@Injectable()
export class HttpBusyInterceptor implements HttpInterceptor {
  constructor(private spinnerService: IAppSpinnerService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skipInteceptor = req.context.get(BusyHttpInterceptor.CONTEXT_TOKEN_SKIP);
    if (skipInteceptor) {
      return next.handle(req);
    } else {
      // Get message from context
      const message = req.context.get(BusyHttpInterceptor.CONTEXT_TOKEN_MESSAGE);

      // Show spinner with message
      this.spinnerService?.showSpinner(message);

      return next.handle(req).pipe(
        finalize(() => {
          // Hide spinner
          this.spinnerService?.hideSpinner();
        })
      );
    }
  }
}
