<div class="date-picker">
  <div class="form-group">
    <label [for]="name" class="input-label">
      <input
        ngbDatepicker
        #d="ngbDatepicker"
        aria-autocomplete="none"
        autocomplete="off"
        tabindex="0"
        class="form-control icon-right"
        datepickerClass="custom-date-picker"
        (click)="d.toggle()"
        [id]="name"
        [name]="name"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [formControl]="formControl" />
      <i class="fal fa-calendar-alt"></i>
    </label>
  </div>
</div>
