import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: 'iframe[sharedHeartbeatListen]' })
export class IFrameHeartbeatDirective {
  @Output() heartbeatEvent: EventEmitter<UIEvent> = new EventEmitter<UIEvent>();

  @HostListener('window:message', ['$event'])
  protected onMessage(e: MessageEvent) {
    if (e && e.isTrusted && !!e.data) {
      const mData = e.data;
      if (!!mData.type && mData.type.toString() === 'oh.heartbeat') {
        this.heartbeatEvent.emit(mData);
      }
    }
  }
}
