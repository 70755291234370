import { Directive, HostListener, Input } from '@angular/core';
import { IRedirectService } from '../services/redirect';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

/**
 * Directive to add clickable redirect event to an element.
 * Excludes anchor links and area as another directive will handle that scenarios.
 */
@Directive({
  selector: ':not(a):not(area)[sharedRedirect]',
})
export class RedirectDirective {
  constructor(readonly redirectService: IRedirectService) {}

  @Input() redirect: string | undefined;

  @Input() set disable(value: boolean) {
    this.disabled = coerceBooleanProperty(value);
  }
  public disabled = false;

  @HostListener('click') onClick() {
    // Skips when disabled
    if (this.disabled) {
      return false;
    }
    // Navigates on the requested link redirecting when necessary
    this.redirectService.navigate(this.redirect ?? '');
    // Prevents default
    return false;
  }
}
