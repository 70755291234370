import { Pipe, PipeTransform } from '@angular/core';
import { IGroup } from './accordion.models';

@Pipe({
  name: 'getAccordionHeader',
})
export class SharedAccordionHeaderPipe implements PipeTransform {
  transform(g: IGroup): string {
    if (!g.heading) {
      return '';
    }
    if (!g.useContentTemplate) {
      return g.heading;
    }
    const hasHTML = g.heading.indexOf('<') > -1;
    return (!hasHTML ? '<span>' : '') + g.heading + (!hasHTML ? '</span>' : '');
  }
}
