import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IAppSpinnerService } from '../services';

// Show the spinner when processing an HTTP request
@Injectable()
export class BusyHttpInterceptor implements HttpInterceptor {
  public static CONTEXT_TOKEN_SKIP = new HttpContextToken<boolean>(() => false);
  public static CONTEXT_TOKEN_MESSAGE = new HttpContextToken<string>(() => 'Loading ...');

  private requestCounter = 0;

  constructor(private spinnerService: IAppSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skipInteceptor = request.context.get(BusyHttpInterceptor.CONTEXT_TOKEN_SKIP);
    if (skipInteceptor) {
      return next.handle(request);
    } else {
      // Get message from context
      const message = request.context.get(BusyHttpInterceptor.CONTEXT_TOKEN_MESSAGE);

      this.requestCounter++;
      // Show spinner with message
      if (this.requestCounter == 1) {
        this.spinnerService?.showSpinner(message);
      }

      return next.handle(request).pipe(
        finalize(() => {
          if (this.requestCounter == 0) return;

          // Hide spinner
          this.requestCounter--;
          if (this.requestCounter == 0) {
            this.spinnerService?.hideSpinner();
          }
        })
      );
    }
  }
}
