/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedIFrameResizeBroadcastService {
  //broadcaster needs to subscribe
  private _broadcasters: { [key: string]: any } = {};

  addComponent(cmp: any, funcName: string) {
    this._broadcasters[cmp.id] = {
      id: cmp.id,
      cmp: cmp,
      funcName: funcName,
    };
  }
  removeComponent(cmp: any) {
    delete this._broadcasters[cmp.id];
  }

  broadcastById(id: string) {
    if (this._broadcasters[id]) {
      const B = this._broadcasters[id];
      if (typeof B.cmp[B.funcName] !== 'undefined') {
        B.cmp[B.funcName].apply(B.cmp, []);
      }
    }
  }

  broadcastAll() {
    const k = Object.keys(this._broadcasters);
    for (let kI = 0, kL = k.length; kI < kL; kI++) {
      this.broadcastById(k[kI]);
    }
  }
  broadcastFirst() {
    const k = Object.keys(this._broadcasters);
    if (k.length > 0) {
      this.broadcastById(k[0]);
    }
  }
}
