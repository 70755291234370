import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { ILanguageService } from './language.interface';
import { ICommonRuntimeEnvironment, IResult, REQUEST_HEADER, SharedServerRouteURI } from '@one-access/shared/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IRuntimeEnvironmentService } from '../runtime-environment';
import { TranslateService } from '@ngx-translate/core';

/**
 * Provides language specific services through dependency-injection.
 */
@Injectable()
export class LanguageService implements ILanguageService {
  private _env: ICommonRuntimeEnvironment;
  public langLoadedSubject = new BehaviorSubject<string>('');
  public langSubject = new BehaviorSubject<string>('en');

  constructor(private http: HttpClient, runtimeEnv: IRuntimeEnvironmentService, translate: TranslateService) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();

    this.langSubject.subscribe((language) => {
      translate.use(language).subscribe(() => {
        this.langLoadedSubject.next(language);
      });
      this.updateLanguageOnServer(language).pipe(take(1)).subscribe();
    });

    // Force get current language.
    this.getCurrentLanguage();
  }

  get current() {
    return this.getCurrentLanguage();
  }

  switchLanguage(language?: string): void {
    const nextLanguage = language || (this.current === 'en' ? 'fr' : 'en');
    this.setLanguageCookie(nextLanguage);
    this.langSubject.next(nextLanguage ?? 'en');
  }

  private getCurrentLanguage() {
    // Check if query parameter is set. If it is, it takes precidence over cookie value.
    const queryParamLanguage = this.getLanguageFromQueryParam();
    if (['en', 'fr'].includes(queryParamLanguage)) {
      // Update the cookie value
      this.setLanguageCookie(queryParamLanguage);
    }
    const matches = document.cookie.match(/language=([^;]+)/);
    const lang = matches ? matches[1] : 'en';
    if (lang !== this.langSubject.getValue()) {
      this.langSubject.next(lang);
    }
    return lang;
  }

  private getLanguageFromQueryParam(): string {
    return new URLSearchParams(window.location.search).get('lang') || '';
  }

  private setLanguageCookie(language: string) {
    const hostName = window.location.hostname;
    const topLevelDomain = hostName.split('.').slice(-2).join('.');
    document.cookie = `language=${language}; domain=${topLevelDomain}; path=/`;
  }

  private updateLanguageOnServer(lang: string): Observable<IResult<boolean>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.Language}/${lang}`;

    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');

    const resp = this.http.get(url, { withCredentials: true, observe: 'response', headers });
    const data = resp.pipe(
      map((x) => {
        return <IResult<boolean>>x.body;
      })
    );

    return data;
  }
}
