import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

@Component({
  selector: 'shared-quick-date-range',
  templateUrl: './quick-date-range.component.html',
  styleUrls: ['./quick-date-range.component.scss'],
})
export class QuickDateRangeComponent implements OnChanges {
  @Input() quickOptions: { name: string; active: boolean }[] = [
    { name: '1W', active: false },
    { name: '1M', active: false },
    { name: '3M', active: true },
    { name: '6M', active: false },
    { name: '1Y', active: false },
  ];
  @Input() translations = '';
  @Input() selectedOption = '3M';
  @Input() startDate!: NgbDateStruct;
  @Input() endDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() - 1,
    day: new Date().getDate(),
  };

  @Output() quickDateSelection: EventEmitter<string> = new EventEmitter();

  onDateRangeSelection(selection: string) {
    this.quickOptions.forEach((o: { name: string; active: boolean }) => (o.active = o.name === selection));
    this.quickDateSelection.emit(selection);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['startDate'] || changes['endDate']) {
      this.syncUpQuickOption(this.startDate, this.endDate);
    }
  }

  syncUpQuickOption(startDate: NgbDateStruct, endDate: NgbDateStruct) {
    let name = '-';
    const momentStartDate = dayjs(new Date(startDate?.year, startDate?.month - 1, startDate?.day));
    const momentEndDate = dayjs(new Date(endDate?.year, endDate?.month - 1, endDate?.day));

    if (momentStartDate && momentEndDate && dayjs().startOf('day').isSame(momentEndDate, 'day')) {
      if (dayjs().startOf('day').subtract(1, 'weeks').isSame(momentStartDate, 'day')) {
        name = '1W';
      }
      if (dayjs().startOf('day').subtract(1, 'months').isSame(momentStartDate, 'day')) {
        name = '1M';
      }
      if (dayjs().startOf('day').subtract(3, 'months').isSame(momentStartDate, 'day')) {
        name = '3M';
      }
      if (dayjs().startOf('day').subtract(6, 'months').isSame(momentStartDate, 'day')) {
        name = '6M';
      }
      if (dayjs().startOf('day').subtract(1, 'years').isSame(momentStartDate, 'day')) {
        name = '1Y';
      }
    }
    this.quickOptions.forEach((o) => (o.active = o.name === name));
  }
}
