import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CMS,
  ICommonRuntimeEnvironment,
  IResult,
  Patient,
  REQUEST_HEADER,
  SharedServerRouteURI,
} from '@one-access/shared/api';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IRuntimeEnvironmentService } from '../runtime-environment';
import { ICMSClient } from './cms.interface';

/**
 * Service that allowed client application to communicate with the backend CMS Client.
 */
@Injectable()
export class CMSClient implements ICMSClient {
  public patientInContext = new BehaviorSubject<boolean>(false);

  private _env: ICommonRuntimeEnvironment;

  constructor(private http: HttpClient, runtimeEnv: IRuntimeEnvironmentService) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
  }

  public language(lang: string): Observable<IResult<boolean>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.Language}/${lang}`;

    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');

    const resp = this.http.get(url, { withCredentials: true, observe: 'response', headers });
    const data = resp.pipe(
      map((x) => {
        return <IResult<boolean>>x.body;
      })
    );

    return data;
  }

  public patientClose(): Observable<IResult<boolean>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.PatientClose}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');

    const resp = this.http.get(url, { withCredentials: true, observe: 'response', headers });
    const data = resp.pipe(
      map((x) => {
        const result = <IResult<boolean>>x.body;
        if (result.data === true) {
          this.patientInContext.next(false);
        }
        return result;
      })
    );

    return data;
  }

  public validateHubTopic(): Observable<IResult<boolean>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.ValidateHubTopic}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp = this.http.get(url, { withCredentials: true, observe: 'response', headers });
    const data = resp.pipe(
      map((x) => {
        const res = <IResult<boolean>>x.body;
        this.patientInContext.next(res.data ?? false);
        return res;
      })
    );
    return data;
  }

  public patientOpen(patientId: string): Observable<IResult<CMS.ApiResponse>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.PatientOpen}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp = this.http.post(url, { patientId }, { withCredentials: true, observe: 'response', headers });
    const data = resp.pipe(
      map((x) => {
        this.patientInContext.next(true);
        return <IResult<CMS.ApiResponse>>x.body;
      })
    );
    return data;
  }

  public currentContext(): Observable<IResult<CMS.ContextResourceObject[]>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.CurrentContext}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp = this.http.get(url, { withCredentials: true, observe: 'response', headers });
    const data = resp.pipe(
      map((x) => {
        this.patientInContext.next(true);
        return <IResult<CMS.ContextResourceObject[]>>x.body;
      })
    );
    return data;
  }

  public currentContextPatient(): Observable<IResult<Patient>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.CurrentContextPatient}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp = this.http.get(url, { withCredentials: true, observe: 'response', headers });
    const data = resp.pipe(
      map((x) => {
        this.patientInContext.next(true);
        return <IResult<Patient>>x.body;
      })
    );
    return data;
  }
}
