export abstract class SharedClientRoutePath {
  static readonly Health = 'health';
  static readonly Error = 'error';
  static readonly SignIn = 'sign-in';
  static readonly SignInUAOs = 'uaos';
  static readonly SignInTerms = 'terms';
  static readonly SignInCallback = 'callback';
  static readonly SignOut = 'sign-out';
  static readonly SignOutCallback = 'callback';
}
