import dayjs from 'dayjs';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAppContextResponse, IPatientContext } from '@one-access/shared/api';
import { Observable } from 'rxjs';

@Component({
  selector: 'shared-patient-card-thin',
  templateUrl: './patient-card-thin.component.html',
  styleUrls: ['./patient-card-thin.component.scss'],
})
export class PatientCardThinComponent implements OnInit {
  @Input() currentAppContext$!: Observable<IAppContextResponse>;

  expanded = false;

  // Patient context
  patientName!: string;
  patientDob!: string;
  patientAge!: string;
  patientGender!: string;
  patientHcn?: string;
  patientMrn?: string;
  patientProv!: string;
  patientPhone!: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    // Observe app context (patient)
    this.currentAppContext$.subscribe((appContext) => {
      // Format patient
      if (appContext?.patient) {
        const patient: IPatientContext = appContext.patient;
        let name = '';
        if (patient.prefix) name = `${patient.prefix} `;
        if (patient.firstName) name += `${patient.firstName} `;
        if (patient.lastName) name += patient.lastName;
        this.patientName = name;

        if (patient.age) {
          this.patientAge = patient.age;
        }
        if (patient.gender) {
          // display translated gender, if no matching translation key then display value imported from cms
          const i18nGender = this.translateService.instant(`shared.patient.gender.${patient.gender?.toLowerCase()}`);
          this.patientGender = i18nGender.startsWith('shared.patient.gender') ? patient.gender : i18nGender;
        }
        if (
          patient.patientId?.system !== 'https://fhir.infoway-inforoute.ca/NamingSystem/ca-on-patient-hcn' &&
          patient.patientId?.value
        ) {
          this.patientMrn = patient.patientId.value;
        } else if (
          patient.hcn ||
          patient.patientId?.system === 'https://fhir.infoway-inforoute.ca/NamingSystem/ca-on-patient-hcn'
        ) {
          this.patientHcn = patient.hcn ?? patient.patientId?.value;
          this.patientProv = '(ON)';
        }

        if (patient.birthDate) {
          this.patientDob = this.date3MFormatter(patient.birthDate);
        }
      }
    });
  }

  // Format the date value to 3M format
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private date3MFormatter(value: any): string {
    return value ? dayjs(value).format('DD MMM YYYY') : '';
  }
}
