import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SessionExpiredComponent } from './expired/expired.component';
import { SessionReminderComponent } from './reminder/reminder.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';

/**
 * Provides functionality to maintain user’s session within the browser. Handle timeouts and expiry of the session.
 */
@NgModule({
  declarations: [SessionExpiredComponent, SessionReminderComponent],
  imports: [CommonModule, CollapseModule, ModalModule.forChild(), TranslateModule.forChild({ extend: true })],
})
export class SessionModule {}
