import { CMS, IResult, Patient } from '@one-access/shared/api';
import { BehaviorSubject, Observable } from 'rxjs';

export abstract class ICMSClient {
  public abstract patientInContext: BehaviorSubject<boolean>;
  public abstract language(lang: string): Observable<IResult<boolean>>;
  public abstract patientClose(): Observable<IResult<boolean>>;
  public abstract validateHubTopic(): Observable<IResult<boolean>>;
  public abstract patientOpen(patientId: string): Observable<IResult<CMS.ApiResponse>>;
  public abstract currentContext(): Observable<IResult<CMS.ContextResourceObject[]>>;
  public abstract currentContextPatient(): Observable<IResult<Patient>>;
}
