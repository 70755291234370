<div class="custom-pagination d-flex flex-column flex-md-row justify-content-between">
  <div class="mt-1 custom-pagination-bar custom-pagination-info d-flex flex-column flex-md-row">
    <div *ngIf="hasPageSizes" class="custom-pagination-bar custom-pagination-pageinfo">
      <label for="slPageSize" class="fw-normal">{{ i18nRecordsPerPage }}</label>
    </div>

    <div *ngIf="hasPageSizes" class="custom-pagination-bar custom-pagination-pageinfo">
      <select (change)="onPageSizeChanged()" name="slPageSize" #slPageSize class="page-input mx-1 form-control">
        <ng-container *ngFor="let pageSizeV of pageSizes">
          <option value="{{ pageSizeV }}" [selected]="pageSizeV === pageSize">
            <p class="fs-sm">{{ pageSizeV }}</p>
          </option>
        </ng-container>
      </select>
    </div>
    <div class="ms-3 custom-pagination-bar custom-pagination-recordinfo">
      <span class="fw-bold" #lbFirstDisplayedRow></span>
      <span class="fw-normal px-1">{{ i18nFromToSep }}</span>
      <span class="fw-bold" #lbLastDisplayedRow></span>
      <span class="fw-normal px-1">{{
        i18nRecordsFromToTotalSep ? i18nRecordsFromToTotalSep : i18nFromToTotalSep
      }}</span>
      <span class="fw-bold" #lbRowCount></span>
      <span class="fw-bold px-1">{{ totalRows === 1 ? i18nRow : i18nRows }}</span>
    </div>
  </div>

  <div class="mt-1 custom-pagination-bar custom-pagination-nav d-flex">
    <button class="btn bg-light" aria-label="double" (click)="onBtFirst()" #btFirst>
      <span class="fal fa-chevron-double"></span>
      <span class="sr-only">{{ i18nFirst }}</span>
    </button>
    <button class="btn bg-light mx-2" aria-label="left" (click)="onBtPrevious()" #btPrev>
      <span class="fal fa-chevron-left"></span>
      <span class="sr-only">{{ i18nPrev }}</span>
    </button>
    <input
      class="form-control w-auto text-center"
      size="2"
      value=""
      aria-label="page"
      pattern="[0-9]"
      #inCurrentPage
      (keyup)="onBtPage($event)"
      (click)="onBtPage($event)"
      (focus)="onBtPage($event)"
      (change)="onBtPage($event)" />
    <span class="p-2 small">
      <span class="fw-normal px-1">{{ i18nPagesFromToTotalSep ? i18nPagesFromToTotalSep : i18nFromToTotalSep }}</span>
      <span #lbTotalPages> </span>
      <span class="fw-normal px-1">{{ totalPages === 1 ? i18nPage : i18nPages }}</span>
    </span>
    <button class="btn bg-light" aria-label="right" (click)="onBtNext()" #btNext>
      <span class="fal fa-chevron-right"></span>
      <span class="sr-only">{{ i18nNext }}</span>
    </button>
    <button class="btn bg-light mx-2" aria-label="double-right" (click)="onBtLast()" #btLast>
      <span class="fal fa-chevron-double-right"></span>
      <span class="sr-only">{{ i18nLast }}</span>
    </button>
  </div>
</div>
