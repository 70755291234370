<div class="footer thin-footer align-items-center" [class.is-mobile]="isMobile">
  <div class="footer-left mt-0">
    <div class="footer-links align-items-center gap-4 gap-lg-5">
      <ng-container *ngFor="let nav of navs">
        <a id="{{ 'linkFooter' + (nav.class | titlecase) }}" [href]="nav.url" [ngClass]="nav.class" target="_blank">{{
          nav.menuitem
        }}</a>
      </ng-container>
    </div>
  </div>
  <div class="footer-right">
    <img
      id="imgFooterLogo"
      class="footer-logo"
      src="{{ 'shared.footer.logo.src' | translate }}"
      alt="{{ 'shared.footer.logo.alt' | translate }}" />
  </div>
</div>
