import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { ICatalogueService } from './catalogue.interface';
import { AppCatalogue, AppsData, ICommonRuntimeEnvironment, SharedClientRouteURI } from '@one-access/shared/api';
import { IRuntimeEnvironmentService } from '../runtime-environment';
import { IAuthService } from '../auth';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IRedirectService } from '../redirect';
import { ErrorTypes } from '@one-access/shared/util';

/**
 * Defines functionality to retrieve catalogue.json file and determine if the user has access to the applications in
 * the catalogue.
 */
@Injectable()
export class CatalogueService implements ICatalogueService {
  private _env: ICommonRuntimeEnvironment;
  private catalogueUrl: string;
  private _catalogue?: AppCatalogue;
  private _patientSearchRequiredSubject = new BehaviorSubject<boolean>(false);
  public isPatientSearchRequired = this._patientSearchRequiredSubject.asObservable();

  constructor(
    private http: HttpClient,
    runtimeEnv: IRuntimeEnvironmentService,
    private authService: IAuthService,
    private redirect: IRedirectService
  ) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
    this.catalogueUrl = this._env.catalogueUrl;
  }

  /**
   * Gets a list of applications that current user is entitled to.
   * @returns An observable that emits a list of apps that the user is entitled to
   */
  getEntitledApps(): Observable<AppsData[]> {
    return this.getContent().pipe(
      map(() => {
        return this.filteredApps;
      })
    );
  }

  /**
   * Retrieve catalogue.json file
   * @returns An observable that emits after AppCatalogue data is retrieved.
   */
  private getContent(): Observable<AppCatalogue> {
    if (this._catalogue) {
      return of(this._catalogue);
    }
    return this.http.get<AppCatalogue>(this.catalogueUrl).pipe(
      first(),
      catchError(() => {
        this.redirect.redirect(`${SharedClientRouteURI.Error}?rc=${ErrorTypes.AppCatalogue.GetContentError.id}`);
        return [];
      }),
      map((res) => {
        this._catalogue = res;
        return this._catalogue;
      })
    );
  }

  getApp(appId: string): Observable<AppsData | undefined> {
    return this.getContent().pipe(
      map(() => {
        return this.filteredApps.find((app) => app.appId === appId);
      })
    );
  }

  /**
   * From the app catalogue data, return only the apps that the user has entitlements.
   */
  private get filteredApps(): AppsData[] {
    const filteredApps = this._catalogue?.apps.filter((app) => {
      return this.isEntitled(app);
    });

    if (filteredApps) {
      this._patientSearchRequiredSubject.next(filteredApps.some((app) => app.patientRequired === true));
    }

    return filteredApps ?? [];
  }

  /**
   * Check if the user has all the entitlements required to launch the application.
   * @param app Data for an individual app from the app catalogue
   * @returns boolean if the user has access to launch the application
   */
  private isEntitled(app: AppsData): boolean {
    let entitled = false;
    let i = 0;
    do {
      let scopeEntitled = true;
      app.authScopes[i].forEach((authScope) => {
        const found =
          (this.authService.currentUAOObj?.service.findIndex((service) => {
            return (
              authScope.entitlement === service.name &&
              (service.attribute.findIndex((scope) => {
                return authScope.scope ? scope.name === 'scope' && scope.value.indexOf(authScope.scope) > -1 : true;
              }) ?? -1) >= 0
            );
          }) ?? -1) >= 0;
        scopeEntitled = scopeEntitled && found;
      });
      entitled = entitled || scopeEntitled;
      i++;
    } while (!entitled && i < app.authScopes.length);

    return entitled;
  }
}
