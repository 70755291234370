<ng-container *ngTemplateOutlet="viewMode === 'mobile' ? mobile : desktop"></ng-container>

<ng-template #desktop>
  <div>
    <form (ngSubmit)="onSubmit()" [formGroup]="dateRangePickerForm">
      <div class="d-flex">
        <div class="flex-column">
          <div class="pe-3">
            <span class="label d-inline-block">{{ quickDateRangeLabel | translate }}</span>
          </div>
          <div class="pe-3 border-end border-gray400">
            <div
              id="quickPickToolbar"
              class="btn-toolbar"
              role="toolbar"
              [attr.aria-label]="quickDateRangeLabel | translate">
              <shared-quick-date-range
                [quickOptions]="quickOptions"
                (quickDateSelection)="setQuickOption($event)"
                [endDate]="dateRangePickerForm.controls['endDate'].value"
                [startDate]="dateRangePickerForm.controls['startDate'].value"
                [translations]="quickDateRangeTranslations">
              </shared-quick-date-range>
            </div>
          </div>
        </div>
        <div class="flex-column" role="toolbar" [attr.aria-label]="dateRangeLabel | translate">
          <div class="ps-3">
            <span class="label d-inline-block">{{ dateRangeLabel | translate }}</span>
          </div>
          <div class="d-flex">
            <div class="ps-3 d-flex flex-row">
              <div class="form-group date-input-group">
                <shared-datepicker
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [formControl]="dateRangePickerForm.controls['startDate'] | formControl"
                  [name]="'startDate'"
                  [ngClass]="{
              'is-invalid':
              (dateRangePickerForm.controls['startDate'].touched ||
              dateRangePickerForm.controls['startDate'].dirty) &&
              (dateRangePickerForm.controls['startDate'].errors || dateRangePickerForm.errors?.['isValidRange'])
            }">
                </shared-datepicker>
                <div
                  *ngIf="dateRangePickerForm.controls['startDate']?.errors || dateRangePickerForm.errors?.['isValidRange']"
                  class="invalid-feedback">
                  <div *ngIf="dateRangePickerForm.controls['startDate']?.errors?.['required']">
                    {{ startDateRequiredError | translate }}
                  </div>
                  <div *ngIf="dateRangePickerForm.controls['startDate']?.errors?.['isValidDate']">
                    {{ startDateInvalidFormatError | translate }}
                  </div>
                  <div *ngIf="!!(dateRangePickerForm.controls['startDate']?.errors?.['ngbDate']?.['maxDate'])">
                    {{ startMaxDateError | translate }}
                  </div>
                  <div *ngIf="!!(dateRangePickerForm.controls['startDate']?.errors?.['ngbDate']?.['minDate'])">
                    {{ startMinDateError | translate }}
                  </div>
                  <div *ngIf="dateRangePickerForm.errors?.['isValidRange']">
                    {{ invalidStartDateRangeError | translate }}
                  </div>
                </div>
              </div>
              <div class="px-2 to">{{ toLabel | translate }}</div>
              <div class="form-group date-input-group">
                <shared-datepicker
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [formControl]="dateRangePickerForm.controls['endDate'] | formControl"
                  [name]="'endDate'"
                  [ngClass]="{'is-invalid':
                    (dateRangePickerForm.controls['endDate'].touched ||
                    dateRangePickerForm.controls['endDate'].dirty) &&
                    (dateRangePickerForm.controls['endDate'].errors || dateRangePickerForm.errors?.['isValidRange'])}">
                </shared-datepicker>
                <div
                  *ngIf="!!(dateRangePickerForm.controls['endDate']?.errors) || dateRangePickerForm.errors?.['isValidRange']"
                  class="invalid-feedback">
                  <div *ngIf="dateRangePickerForm.controls['endDate']?.errors?.['required']">
                    {{ endDateRequiredError | translate }}
                  </div>
                  <div *ngIf="!!(dateRangePickerForm.controls['endDate']?.errors?.['ngbDate']?.['maxDate'])">
                    {{ endMaxDateError | translate }}
                  </div>
                  <div *ngIf="!!(dateRangePickerForm.controls['endDate']?.errors?.['ngbDate']?.['minDate'])">
                    {{ endMinDateError | translate }}
                  </div>
                  <div *ngIf="dateRangePickerForm.controls['endDate']?.errors?.['isValidDate']">
                    {{ endDateInvalidFormatError | translate }}
                  </div>
                  <div *ngIf="dateRangePickerForm.errors?.['isValidRange']">
                    {{ invalidEndDateRangeError | translate }}
                  </div>
                </div>
              </div>
              <div class="ps-3 form-group">
                <button
                  [disabled]="dateRangePickerForm.invalid"
                  id="filter-search-btn"
                  type="submit"
                  class="btn btn-primary">
                  {{ searchButtonText | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #mobile>
  <div class="row ps-3 pe-2">
    <div
      class="d-flex justify-content-between filter-terms p-0"
      tabindex="0"
      (click)="togglePeriodFilter()"
      (keydown.enter)="togglePeriodFilter()">
      <div class="filter-daterange">
        {{ dateRange }}
      </div>
      <div>
        <button
          class="bg-transparent border-0 p-0 text-primary text-center"
          style="margin-right: 12px"
          [ngClass]="showDateRangeForm ? 'fal fa-chevron-up' : 'fal fa-chevron-down'">
          <span class="visually-hidden">{{
            (showDateRangeForm ? 'shared.dateRange.hide' : 'shared.dateRange.show') | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>

  <form class="date-form" *ngIf="showDateRangeForm" (ngSubmit)="onSubmit()" [formGroup]="dateRangePickerForm">
    <div class="d-flex flex-column ps-3 pe-3">
      <div class="form-group col-md-6 mt-3">
        <label>{{ quickDateRangeLabel | translate }}</label>
      </div>
      <div class="row">
        <div class="btn-toolbar p-0" role="toolbar" aria-label="quick access toolbar">
          <div class="btn-group" role="group" aria-label="quick access group">
            <shared-quick-date-range
              [quickOptions]="quickOptions"
              (quickDateSelection)="setQuickOption($event)"
              [endDate]="dateRangePickerForm.controls['endDate'].value"
              [startDate]="dateRangePickerForm.controls['startDate'].value"
              [translations]="quickDateRangeTranslations">
            </shared-quick-date-range>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 mt-3 p-0">
          <label>{{ dateRangeLabel | translate }}</label>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 mt-3 p-0">
          <label for="startDate">{{ startDateLabel | translate }}</label>
          <shared-datepicker
            [minDate]="minDate"
            [maxDate]="maxDate"
            [formControl]="dateRangePickerForm.controls['startDate'] | formControl"
            [name]="'startDate'"
            [ngClass]="{'is-invalid':
                            (dateRangePickerForm.controls['startDate'].touched ||
                            dateRangePickerForm.controls['startDate'].dirty) &&
                            (dateRangePickerForm.controls['startDate'].errors ||
                            dateRangePickerForm.errors?.['isValidRange'])
                          }">
          </shared-datepicker>
          <div
            *ngIf="dateRangePickerForm.controls['startDate']?.errors || dateRangePickerForm.errors?.['isValidRange']"
            class="invalid-feedback">
            <div *ngIf="dateRangePickerForm.controls['startDate']?.errors?.['required']">
              {{ startDateRequiredError | translate }}
            </div>
            <div *ngIf="dateRangePickerForm.controls['startDate']?.errors?.['isValidDate']">
              {{ startDateInvalidFormatError | translate }}
            </div>
            <div *ngIf="!!(dateRangePickerForm.controls['startDate']?.errors?.['ngbDate']?.['maxDate'])">
              {{ startMaxDateError | translate }}
            </div>
            <div *ngIf="!!(dateRangePickerForm.controls['startDate']?.errors?.['ngbDate']?.['minDate'])">
              {{ startMinDateError | translate }}
            </div>
            <div *ngIf="dateRangePickerForm.errors?.['isValidRange']">
              {{ invalidStartDateRangeError | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 mt-3 p-0">
          <label for="endDate">{{ endDateLabel | translate }}</label>
          <shared-datepicker
            [minDate]="minDate"
            [maxDate]="maxDate"
            [formControl]="dateRangePickerForm.controls['endDate'] | formControl"
            [name]="'endDate'"
            [ngClass]="{
            'is-invalid':
            (dateRangePickerForm.controls['endDate'].touched ||
            dateRangePickerForm.controls['endDate'].dirty) &&
            (dateRangePickerForm.controls['endDate'].errors || dateRangePickerForm.errors?.['isValidRange'])
          }">
          </shared-datepicker>
          <div
            *ngIf="dateRangePickerForm.controls['endDate']?.errors || dateRangePickerForm.errors?.['isValidRange']"
            class="invalid-feedback">
            <div *ngIf="dateRangePickerForm.controls['endDate']?.errors?.['required']">
              {{ endDateRequiredError | translate }}
            </div>
            <div *ngIf="dateRangePickerForm.controls['endDate']?.errors?.['isValidDate']">
              {{ endDateInvalidFormatError | translate }}
            </div>
            <div *ngIf="!!(dateRangePickerForm.controls['endDate']?.errors?.['ngbDate']?.['maxDate'])">
              {{ endMaxDateError | translate }}
            </div>
            <div *ngIf="!!(dateRangePickerForm.controls['endDate']?.errors?.['ngbDate']?.['minDate'])">
              {{ endMinDateError | translate }}
            </div>
            <div *ngIf="dateRangePickerForm.errors?.['isValidRange']">
              {{ invalidEndDateRangeError | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 d-flex justify-content-end p-0 button-group">
          <button
            [disabled]="dateRangePickerForm.invalid"
            id="filter-search-btn"
            type="button"
            (click)="cancelFilter()"
            class="btn btn-outline-primary">
            {{ cancelButtonText | translate }}
          </button>
          <button [disabled]="dateRangePickerForm.invalid" id="filter-search-btn" type="submit" class="btn btn-primary">
            {{ okButtonText | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
