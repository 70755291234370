import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorService, IErrorService, IModalService } from '../services';
import { HttpErrorInterceptor } from '../interceptors';
import { PageModule } from '../page';
import { ErrorDialogComponent } from './dialog/dialog.component';

/**
 * Exposes functionality to automatically detect if there is an error in the API response and initiate error modal
 * popup.
 */
@NgModule({
  declarations: [ErrorDialogComponent],
  imports: [CommonModule, PageModule, CollapseModule, ModalModule.forChild()],
  providers: [
    { provide: IErrorService, useClass: ErrorService, deps: [IModalService] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [IErrorService],
    },
  ],
})
export class ErrorModule {}
