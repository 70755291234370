<div class="card shadow-sm pt-3 patient-banner mb-3" *ngIf="patient$ | async as patient">
  <div class="d-flex flex-column flex-md-row mb-2 flex-wrap">
    <div class="flex-shrink-0">
      <div id="contact-display-expand-button" class="d-md-none mt-2">
        <span
          id="shared_patient_banner_contact_toggle"
          (click)="toggleContactInfo()"
          class="link-primary fal py-3 px-3"
          [ngClass]="{
            'fa-chevron-double-down': (showContactInfo$ | async) === false,
            'fa-chevron-double-up': (showContactInfo$ | async)
          }">
        </span>
      </div>
      <div class="d-flex">
        <div class="flex-shrink-0 px-2 ms-1 align-self-md-center text-end">
          <div class="flex-column avatar">
            <div class="fas fa-profile-avatar text-primary">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 pe-md-4">
          <label id="shared_patient_banner_name" class="mb-0">
            {{ patient.familyName }}, {{ patient.givenName }} {{ patient.middleName }}
          </label>
          <div class="d-flex">
            <div class="fs-sm">
              <span id="shared_patient_banner_gender">{{
                'shared.messages.gender.' + (patient.gender | lowercase) | translate
              }}</span>
              |
              <span id="shared_patient_banner_age">{{ 'shared.messages.age' | translate: { age: patient.age } }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vertical-row opacity-25 d-none d-md-block">&nbsp;</div>
    <div class="flex-shrink-1 sm-indent pt-1">
      <div class="d-flex">
        <div class="flex-shrink-0 ps-md-4">
          <span class="fa-light fa-id-card fa-fw text-primary"></span>
        </div>
        <div class="flex-shrink-1 align-self-center ps-2 pe-4">
          <div
            id="shared_patient_banner_label_hcn"
            class="fs-sm fw-bold d-none d-md-block"
            *ngIf="patient.hcn.value !== ''; else mrnLabel">
            {{ 'workspace.searchResults.labelHCN' | translate }}
          </div>
          <ng-template #mrnLabel>
            <div
              id="shared_patient_banner_label_mrn"
              class="fs-sm fw-bold d-none d-md-block"
              *ngIf="patient.mrn !== ''">
              {{ 'workspace.searchResults.labelMRN' | translate }}
            </div>
          </ng-template>
          <div class="fs-sm">
            <span id="shared_patient_banner_hcn" class="fs-sm" *ngIf="patient.hcn.value !== ''; else mrnValue">
              {{ patient.hcn.value | hcn }} ({{ patient.hcn.prov }})
              <span id="shared_patient_banner_label_mobile_hcn" class="d-inline-block d-md-none"
                >| {{ 'workspace.searchResults.labelHCN' | translate }}</span
              ></span
            >
            <ng-template #mrnValue>
              <span id="shared_patient_banner_mrn" class="fs-sm" *ngIf="patient.mrn !== ''">
                {{ patient.mrn }}
                <span id="shared_patient_banner_label_mobile_mrn" class="d-inline-block d-md-none"
                  >({{ 'workspace.searchResults.labelMRN' | translate }})</span
                >
              </span>
            </ng-template>
            <span
              id="shared_patient_banner_label_missing_hcn"
              class="fs-sm"
              *ngIf="patient.mrn === '' && patient.hcn.value === ''"
              >{{ 'workspace.searchResults.missingHCNNumber' | translate }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-25 d-none d-md-block">&nbsp;</div>
    <!-- DOB -->
    <div class="flex-shrink-1 sm-indent pt-1">
      <div class="d-flex">
        <div class="flex-shrink-0 ps-md-4">
          <span class="fal fa-calendar-alt fa-fw text-primary"></span>
        </div>

        <div class="flex-shrink-1 align-self-center ps-2 pe-4">
          <div id="shared_patient_banner_label_dob" class="fs-sm fw-bold d-none d-md-block">
            {{ 'workspace.searchResults.labelDOB' | translate }}
          </div>
          <div id="shared_patient_banner_dob" class="fs-sm">
            {{ patient.dob | localizedDate }}
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-25 d-none d-md-block" *ngIf="patient.address">&nbsp;</div>
    <div
      class="flex-shrink-0 sm-indent pt-1 d-none d-md-block"
      [ngClass]="{ 'display-on-mobile': showContactInfo$ | async }"
      *ngIf="patient.address">
      <div class="d-flex">
        <div class="flex-shrink-0 ps-md-4">
          <span class="fal fa-map-marker-alt fa-fw text-primary"></span>
        </div>

        <div class="flex-shrink-1 align-self-center ps-2 pe-4">
          <div id="shared_patient_banner_label_contact_info" class="fs-sm fw-bold d-none d-md-block">
            {{ 'workspace.searchResults.labelContactInfo' | translate }}
          </div>

          <a
            href="javascript:void(0)"
            id="shared_patient_banner_contact_display_button"
            class="d-none d-md-block text-decoration-none"
            (click)="toggleContactInfo()">
            <span class="px-1 text-black">{{
              'shared.messages.contact.' + ((showContactInfo$ | async) ? 'hide' : 'show') | translate
            }}</span>
            <span
              aria-hidden="true"
              role="presentation"
              class="fal fa-fw-flex text-primary ms-4"
              [ngClass]="{
                'fa-chevron-down': (showContactInfo$ | async) === false,
                'fa-chevron-up': (showContactInfo$ | async)
              }"></span>
          </a>
          <div
            id="shared_patient_banner_contact_info_container"
            class="fade"
            [ngClass]="{ show: showContactInfo$ | async }"
            *ngIf="showContactInfo$ | async">
            <div id="shared_patient_banner_contact_info_container_address" class="fs-sm">
              {{
                (patient.address.line1 ? patient.address.line1 : '') +
                  (patient.address.line2 ? ' ' + patient.address.line2 : '')
              }}
            </div>
            <div class="fs-sm">
              <span id="shared_patient_banner_contact_info_container_city">{{ patient.address.city }}</span
              >{{ patient.address.province ? ', ' : ''
              }}<span id="shared_patient_banner_contact_info_container_province">{{
                patient.address.province + ' '
              }}</span>
              <span id="shared_patient_banner_contact_info_container_postal_code">{{
                patient.address.postalCode | postalCode
              }}</span>
            </div>
            <div id="shared_patient_banner_contact_info_container_phone" class="fs-sm">
              {{ patient.homePhone | phone }}
            </div>
            <div id="shared_patient_banner_contact_info_container_email" class="fs-sm">
              {{ patient.email }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="change-patient-container" class="text-center d-block d-lg-block py-1 py-md-2 ms-auto me-5">
      <a
        href="javascript:void()"
        id="shared_patient_banner_change_patient_link"
        class="text-decoration-none"
        title="{{ 'dashboard.changePatient' | translate }}"
        (click)="changePatient()">
        <span class="d-none d-md-block">
          <span class="fal fa-user-edit"></span>
          {{ 'dashboard.changePatient' | translate }}
        </span>
        <span
          id="shared_patient_banner_change_patient_link_chevron"
          class="d-block d-md-none fa-light fa-chevron-left text-primary"></span>
      </a>
    </div>
  </div>
</div>
