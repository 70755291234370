import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SharedConstant } from '@one-access/shared/util';
import dayjs from 'dayjs';

export function localizedDateValidator(locale?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let valid = true;
    let value = control.value ?? '';
    // Manipulation needed only for French.
    if (value) {
      if (locale === 'fr') {
        // Convert the input to a lower case
        value = value.toLowerCase();
        for (let index = 0; index < SharedConstant.FRENCH_MONTHS_SHORT.length; index++) {
          let item = SharedConstant.FRENCH_MONTHS_SHORT[index];
          if (item.indexOf('.') > 0) {
            item = item.substring(0, item.indexOf('.'));
          }
          const valueIndex = value.indexOf(item);
          if (valueIndex >= 0) {
            const valueEndIndex = value.indexOf(' ', valueIndex);
            const replaceValue = value.substring(valueIndex, valueEndIndex);
            value = value.replace(replaceValue, SharedConstant.ENGLISH_MONTHS_SHORT[index]);
            break;
          }
        }
      }
      const dateParsed = dayjs(value);
      valid = dateParsed.isValid();
    }
    return !valid ? { localizedDate: { value: control.value } } : null;
  };
}
