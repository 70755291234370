import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICommonRuntimeEnvironment } from '@one-access/shared/api';
import { SharedConstant } from '@one-access/shared/util';
import { take } from 'rxjs/operators';
import { IRuntimeEnvironmentService } from './runtime-environment.interface';

/**
 * Loads /assets/runtime-environment.json file created during deployment and makes the content available to the
 * application.
 * use: `this.runtimeEnvironmentService.environment.varName`
 */
@Injectable()
export class RuntimeEnvironmentService implements IRuntimeEnvironmentService {
  // Defaults, get's replaced with values from runtime-environment.json file.
  private _environment: unknown = <ICommonRuntimeEnvironment>{
    debug: false,
    appUrl: '/',
    apiEnable: false,
    apiUrl: '/api',
    reminderStartsAtMSec: 0,
    reminderRefreshIntervalMSec: 15000,
    feedbackUrl: '',
    termsUrl: '',
    catalogueUrl: '',
  };

  constructor(private http: HttpClient) {}

  public environment<T>(): T {
    return <T>this._environment;
  }

  public update<T>(appVersion?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get<T>(`${SharedConstant.RUNTIME_ENVIRONMENT_FILE_URI}?v=${appVersion}`)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this._environment = <T>res;
          },
          complete: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }
}

/**
 * Initiates downloading of the runtime-environment.json file and initialize the service values.
 * @param runtimeEnvironmentService IRuntimeEnvironmentService
 * @returns a promise to download runtime-environment.json file and initialized
 */
export function runtimeEnvironmentFactory<T>(
  runtimeEnvironmentService: IRuntimeEnvironmentService,
  appVersion: string
): () => Promise<void> {
  return () => runtimeEnvironmentService.update<T>(appVersion);
}
