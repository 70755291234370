export abstract class SharedClientRouteData {
  static readonly Title = 'title';
  static readonly HideHeader = 'hideHeader';
  static readonly HideFooter = 'hideFooter';
  static readonly StickyFooter = 'stickyFooter';
  static readonly HideSignInLink = 'hideSignInLink';
  static readonly FullHeaderMenu = 'fullHeaderMenu';
  static readonly EnableSharedTimeoutModal = 'enableSessionTimeoutModal';
  static readonly HasMobilePopupFooter = 'hasMobilePopupFooter';
}
