import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IFrameResizeDirective } from './iframe-resize.directive';
import { IFrameHeartbeatDirective } from './iframe-heartbeat.directive';
import { SharedIFrameContentResizeComponent } from './iframe-resize.component';

/**
 * Exposes functionality required to handle iFrames.
 */
@NgModule({
  declarations: [IFrameResizeDirective, IFrameHeartbeatDirective, SharedIFrameContentResizeComponent],
  imports: [CommonModule],
  exports: [IFrameResizeDirective, IFrameHeartbeatDirective, SharedIFrameContentResizeComponent],
})
export class SharedIFrameModule {}
