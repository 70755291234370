import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { IAppSpinnerState, AppSpinnerService } from '../../services';

/**
 * Component that displays spinner.
 */
@Component({
  selector: 'shared-app-spinner',
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.scss'],
})
export class AppSpinnerComponent implements OnInit, OnDestroy {
  @Input() style: string;
  @Input() color: string;
  @Input() message: string;
  @Input() messageStyle: string;

  private spinnerServiceSub?: Subscription;
  spinner!: IAppSpinnerState;

  constructor(private spinnerService: AppSpinnerService) {
    // Set defaults
    this.style = '';
    this.color = '#FFF';
    this.message = 'Loading ...';
    this.messageStyle = '';
  }

  ngOnInit(): void {
    // Subscribe to spinner service events
    this.spinnerServiceSub = this.spinnerService.spinnerState$.subscribe((spinnerState: IAppSpinnerState) => {
      // Use message from service, otherwise default
      spinnerState.message = spinnerState.message ?? this.message;
      this.spinner = spinnerState;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from spinner service events
    if (this.spinnerServiceSub) this.spinnerServiceSub.unsubscribe();
  }
}
