<div class="patient-header-container">
  <div class="patient-card thin-patient-card flex-column flex-lg-row">
    <div class="d-lg-flex flex-row header-controls">
      <div class="patient d-lg-flex flex-row">
        <div class="d-flex">
          <span id="iconPatientAvatar" class="fas fa-profile-avatar fs-3 oh-avatar">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
          </span>
          <div class="text name-age d-flex align-items-lg-center flex-column flex-lg-row px-3">
            <div id="patientName" class="fw-bold">{{ patientName }}</div>
            <div class="age fs-8">
              <span id="patientGender">{{ patientGender | titlecase }}</span>
              <span class="separator px-1" aria-hidden="true">|</span>
              <span id="patientAge">{{ patientAge }} {{ 'shared.patient.labelAge' | translate }}</span>
            </div>
            <div class="hcn d-flex">
              <div class="icon"><span id="iconHcn" class="fal fa-hcn text-primary"></span></div>
              <div id="patientHcn" class="value" *ngIf="patientHcn">
                {{ patientHcn | hcn }} {{ patientProv }} <span class="separator" aria-hidden="true">|</span>
                {{ 'shared.patient.labelHCN' | translate }}
              </div>
              <div id="patientMrn" class="value" *ngIf="patientMrn">
                {{ patientMrn }} <span class="separator" aria-hidden="true">|</span>
                {{ 'shared.patient.labelMrn' | translate }}
              </div>
            </div>
            <div class="dob d-flex">
              <div class="icon"><span id="iconDob" class="fal fa-calendar-alt text-primary"></span></div>
              <div class="value">
                <div id="patientDob">{{ patientDob }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
