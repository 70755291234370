import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IModalService, IRedirectService } from '../../../services';

/**
 * Component that display feedback modal.
 */
@Component({
  selector: 'shared-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent {
  constructor(public bsModalRef: BsModalRef, private redirect: IRedirectService, private modalService: IModalService) {}

  hideModal(): void {
    this.modalService.hideById(this.bsModalRef.id);
  }

  openFeedback() {
    this.hideModal();
    this.redirect.navigate(this.bsModalRef.content.url, { target: '_blank' });
  }
}
