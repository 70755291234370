import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';

// TODO: Cleanup use of any
@Component({ template: '' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class CustomFormControl implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected _val!: any;
  protected _formControl!: FormControl;
  protected _name!: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  onTouch: any = () => {};

  @Input()
  set formControl(formControl: FormControl) {
    this._formControl = formControl;
  }

  get formControl(): FormControl {
    return this._formControl;
  }

  @Input()
  set name(name: string) {
    this._name = name;
  }

  get name(): string {
    return this._name;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(val: any) {
    this._val = val;
    this.onChange(this._val);
    this.onTouch(this._val);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    return this._val;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(val: any): void {
    this.value = val;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
