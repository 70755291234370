import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ICommonRuntimeEnvironment,
  IResult,
  PCR,
  Patient,
  REQUEST_HEADER,
  SharedServerRouteURI,
} from '@one-access/shared/api';
import { map, Observable } from 'rxjs';
import { IPCRService } from './pcr.interface';
import { IRuntimeEnvironmentService } from '../runtime-environment';

/**
 * Service that allows communication with backend API service to search and retrieve PCR data.
 */
@Injectable()
export class PCRService implements IPCRService {
  private _env: ICommonRuntimeEnvironment;

  constructor(private http: HttpClient, runtimeEnv: IRuntimeEnvironmentService) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
  }

  public search(payload: PCR.IPatientSearchData): Observable<IResult<Patient[]>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.PCR.Root}${SharedServerRouteURI.PCR.Search}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp = this.http.post(url, payload, { withCredentials: true, observe: 'response', headers });
    const data = resp.pipe(
      map((x) => {
        return <IResult<Patient[]>>x.body;
      })
    );
    return data;
  }
}
