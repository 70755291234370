import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PcoiModalContentComponent } from './pcoi-modal-content.component';

@Component({
  selector: 'shared-pcoi-modal',
  template: ``,
})
export class PcoiModalComponent {
  @Input() pcoiLaunchUrl = '';

  private _pcoiModal!: NgbModalRef;
  constructor(private _modalService: NgbModal) {}

  closeModal() {
    if (this._pcoiModal) {
      this._pcoiModal.close();
    }
  }
  openModal() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    };
    this._pcoiModal = this._modalService.open(PcoiModalContentComponent, ngbModalOptions);
    this._pcoiModal.componentInstance.pcoiLaunchUrl = this.pcoiLaunchUrl;
  }
}
