import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUIUtil } from '../helpers';

import { FooterThinComponent } from './footer-thin/footer-thin.component';
import { HeaderThinComponent } from './header-thin/header-thin.component';
import { PatientCardThinComponent } from './patient-card-thin/patient-card-thin.component';
import { HealthCardNumberPipe } from './pipes/hcn/hcn.pipe';

@NgModule({
  declarations: [FooterThinComponent, HeaderThinComponent, PatientCardThinComponent, HealthCardNumberPipe],
  imports: [CommonModule, TranslateModule.forChild({ extend: true }), RouterModule.forChild([])],
  exports: [FooterThinComponent, HeaderThinComponent, PatientCardThinComponent, HealthCardNumberPipe],
  providers: [SharedUIUtil],
})
export class StandaloneModule {}
