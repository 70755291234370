import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResult, Status } from '@one-access/shared/api';
import { CommonError, UtilCommon } from '@one-access/shared/util';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { IErrorService } from '../services';

/**
 * Intercepts http call errors and initiates modal popup to display error message to the user.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public static CONTEXT_TOKEN_SKIP = new HttpContextToken<boolean>(() => false);
  constructor(private errorService: IErrorService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skipInteceptor = req.context.has(HttpErrorInterceptor.CONTEXT_TOKEN_SKIP)
      ? req.context.get(HttpErrorInterceptor.CONTEXT_TOKEN_SKIP)
      : false;
    if (skipInteceptor) {
      return next.handle(req);
    } else {
      return next.handle(req).pipe(
        tap((resp: HttpEvent<unknown>) => {
          const respData = <IResult<void>>(<HttpResponse<unknown>>resp).body;
          if (respData?.status && respData.status !== Status.Ok) {
            const error = <CommonError>respData.error;
            this.errorService.openModal(error?.errorType);
          }
        }),
        catchError((err) => {
          const errorType = UtilCommon.httpErrorType(err.status);
          this.errorService.openModal(errorType);
          return throwError(() => err);
        })
      );
    }
  }
}
