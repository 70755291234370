import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PatientCardComponent } from './card/card.component';
import { LocalizedDatePipe } from './pipes/localized-date/localized-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { HealthCardNumberPipe } from './pipes/hcn/hcn.pipe';
import { PhoneNumberPipe } from './pipes/phone/phone.pipe';
import { PostalCodePipe } from './pipes/postal-code/postal-code.pipe';
import { PatientBannerComponent } from './banner/banner.component';

/**
 * Exposes functionality to display and format information on patient card.
 */
@NgModule({
  declarations: [
    PatientCardComponent,
    PatientBannerComponent,
    LocalizedDatePipe,
    HealthCardNumberPipe,
    PhoneNumberPipe,
    PostalCodePipe,
  ],
  imports: [CommonModule, TranslateModule.forChild({ extend: true })],
  exports: [PatientCardComponent, PatientBannerComponent, LocalizedDatePipe],
  providers: [DatePipe],
})
export class SharedPatientModule {}
