import { Injectable } from '@angular/core';
import { ICommonRuntimeEnvironment } from '@one-access/shared/api';
import { BehaviorSubject, Subscription, first } from 'rxjs';
import { IRuntimeEnvironmentService } from '../runtime-environment';
import { IAuthService } from '../auth';
import { ISessionService } from './session.interface';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { IModalService } from '../modal';
import { SessionExpiredComponent, SessionReminderComponent } from '../../session';

@Injectable()
export class SessionService implements ISessionService {
  private _env: ICommonRuntimeEnvironment;
  private reminderInMSec = 0;

  private timeoutId?: ReturnType<typeof setTimeout>;
  private timerIntervalId?: ReturnType<typeof setInterval>;

  private _showReminderPopupSubject = new BehaviorSubject<boolean>(false);

  private _showExpiredPopupSubject = new BehaviorSubject<boolean>(false);

  private authStatusSub: Subscription;

  private initialState: ModalOptions = {
    initialState: {},
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered',
  };
  private showModal = false;

  public appIdSubject = new BehaviorSubject<string>('');
  public appId$ = this.appIdSubject.asObservable();

  constructor(
    runtimeEnv: IRuntimeEnvironmentService,
    private authService: IAuthService,
    private modalService: IModalService
  ) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
    this.reminderInMSec = this._env.reminderStartsAtMSec;

    this.authService.authTTL$.subscribe((data: number) => {
      if (data === -2) {
        this.authService.cancelAuthTTL();
        this.authService.auth().pipe(first()).subscribe();
      } else {
        this.authService.setReminderTimer(Math.floor(data / 60), data % 60);
      }
    });

    this.authService.reminderTimer$.subscribe((data) => {
      const timerInMSec = (data.min * 60 + data.sec) * 1000;

      if (this.reminderInMSec && timerInMSec > 0 && timerInMSec <= this.reminderInMSec) {
        this._showReminderPopupSubject.next(true);
      } else {
        this._showReminderPopupSubject.next(false);
      }
    });

    this.authStatusSub = this.authService.status$.subscribe((status) => {
      if (!status.isAuthenticated) {
        this.userIsNotAuthenticated();
      } else {
        this.userIsAuthenticated();
      }
    });

    this._showReminderPopupSubject.subscribe((show) => {
      if (show && this.showModal) {
        if (!this.modalService.exists(SessionReminderComponent.name)) {
          this.modalService.hideAll();
          this.modalService.show(SessionReminderComponent.name, SessionReminderComponent, this.initialState);
        }
      } else {
        this.modalService.hide(SessionReminderComponent.name);
      }
    });

    this._showExpiredPopupSubject.subscribe((show) => {
      if (show && this.showModal) {
        this.modalService.hideAll();
        this.modalService.show(SessionExpiredComponent.name, SessionExpiredComponent, this.initialState);
      }
    });
  }

  /**
   * Enable the display of the modal popup. Allows to disable the popup on certain pages. Should be disabled on sign-in page.
   * @param enable boolean
   */
  public enableModal(enable: boolean): void {
    this.showModal = enable;
  }

  /**
   * Heart beat executes this function to extend the session.
   * Disable session extension during reminder or expired popup is displayed.
   */
  public extendSession(): void {
    if (!this._showReminderPopupSubject.value && !this._showExpiredPopupSubject.value) {
      this.authService.extendSession();
    }
  }

  /**
   * User is not authenticated. Hide reminder popup and show session expired popup.
   */
  private userIsNotAuthenticated() {
    this._showReminderPopupSubject.next(false);
    this._showExpiredPopupSubject.next(true);
    clearInterval(this.timerIntervalId);
    this.authStatusSub?.unsubscribe();
    this.authService.cancelAuthTTL();
    this._showExpiredPopupSubject.next(true);
  }

  /**
   * User is authenticated. Subscribe to authTTL.
   * Reset timeout to start the countdown.
   * Setup interval to countdown the timer.
   */
  private userIsAuthenticated() {
    this._showReminderPopupSubject.next(false);
    this.authService.getAuthTTL(this.authService.status.sessionId);

    const timeLeftToShowReminderMSec = this.authService.authTTL * 1000 - this.reminderInMSec;

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(
      () => {
        if (!this.timerIntervalId) {
          this.timerIntervalId = setInterval(() => {
            const timerData = this.authService.getReminderTimer();
            if (timerData.min > 0 || timerData.sec > 0) {
              if (timerData.sec > 0) {
                timerData.sec--;
              } else {
                if (timerData.min > 0) {
                  timerData.sec = 59;
                  timerData.min--;
                }
              }
              this.authService.setReminderTimer(timerData.min, timerData.sec);
            }
          }, 1000);
        }
      },
      timeLeftToShowReminderMSec > 0 ? timeLeftToShowReminderMSec : 0
    );
  }
}
