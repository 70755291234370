import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { APP_VERSION } from '../version';

/**
 * Provides the entry point for the application.
 */
@Component({
  selector: 'launcher-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  protected version: string;
  constructor(translate: TranslateService, title: Title) {
    // TODO: Set title dynamically for each page from routes.
    translate.get('shared.oneAccess.pageTitle').subscribe((_title) => {
      title.setTitle(_title);
    });
    // TODO: Set metadata dynamically.
    this.version = APP_VERSION;
  }
}
