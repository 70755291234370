import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shared-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoModalComponent {
  @Input() imageSrc = '/assets/icons/icon-one-labs.svg';
  @Input() heading = 'LABS.INFO.HEADING';
  @Input() subHeading = 'LABS.INFO.SUBHEADING';
  @Input() btnCloseText = 'Close';
  @Input() closeOthers = true;
  @Input() isAnimated = true;
  @Input() accordianGroup = [
    {
      title: 'LABS.INFO.ABOUT.TITLE',
      description: 'LABS.INFO.ABOUT.DESCRIPTION',
    },
    {
      title: 'LABS.INFO.TECHNICAL_SUPPORT.TITLE',
      description: 'LABS.INFO.TECHNICAL_SUPPORT.DESCRIPTION',
    },
  ];
}
