export class AppConstant {
  // Application
  static readonly APP_KEY = 'launcher';

  // Session
  static readonly SESSION_COOKIE_PREFIX = 'one-access';

  // Redis
  static readonly REDIS_APPLICATION_PREFIX = 'one-access:';
  static readonly REDIS_NOMENCLATURE_PREFIX = 'meta:';
}
