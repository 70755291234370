import { SharedClientRoutePath } from '@one-access/shared/api';

export class ClientRoutePath extends SharedClientRoutePath {
  static readonly Register = 'register';
  static readonly User = 'user';
  static readonly Launch = 'launch';
  static readonly Help = 'help';
  static readonly Apps = 'apps';
  static readonly Patient = {
    Root: 'patient',
    Close: 'close',
    Search: {
      Root: 'search',
      Result: 'result',
    },
  };
  static readonly Redirect = 'redirect';
}
