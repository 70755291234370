import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccordionModule } from 'ngx-bootstrap/accordion';

import { SharedAccordionComponent } from './accordion.component';
import { SharedAccordionHeaderPipe } from './accordion.pipe';

@NgModule({
  imports: [CommonModule, AccordionModule.forRoot()],
  declarations: [SharedAccordionComponent, SharedAccordionHeaderPipe],
  exports: [SharedAccordionComponent, SharedAccordionHeaderPipe],
})
export class SharedAccordionModule {}
