import { Injectable } from '@angular/core';
import { Patient } from '@one-access/shared/api';
import { BehaviorSubject } from 'rxjs';
import { IPatientService } from './patient.interface';

/**
 * Defines functionality to save and retrieve patient search results.
 */
@Injectable()
export class PatientService implements IPatientService {
  private readonly _patientsFromSearchResult = new BehaviorSubject<Patient[]>([]);
  readonly patientsFromSearchResult$ = this._patientsFromSearchResult.asObservable();

  get patientsFromSearchResult(): Patient[] {
    return this._patientsFromSearchResult.getValue();
  }

  set patientsFromSearchResult(val: Patient[]) {
    this._patientsFromSearchResult.next(val);
  }

  getPatientFromSearchResult(id: string): Patient | undefined {
    if (id) {
      const patient = this.patientsFromSearchResult.find((patient) => patient.id === id);
      return patient;
    }
    return undefined;
  }
}
