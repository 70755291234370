import { Directive, HostBinding } from '@angular/core';
import { IRedirectService } from '../services/redirect';
import { RedirectDirective } from './redirect.directive';

/**
 * Directive to add redirect event based on href attribute of another link or area.
 */
@Directive({
  selector: 'a[sharedRedirect],area[sharedRedirect]',
})
export class RedirectWithHrefDirective extends RedirectDirective {
  constructor(redirectService: IRedirectService) {
    super(redirectService);
  }

  // Binds the requested url to the href property
  @HostBinding('href') get href() {
    return this.redirect;
  }
}
