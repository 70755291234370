export abstract class SharedConstant {
  static readonly DEFAULT_COOKIE_MAX_AGE_MSEC = 60000;
  static readonly RUNTIME_ENVIRONMENT_FILE_URI = '/assets/runtime-environment.json';

  // Redis
  static readonly REDIS_KEYS = {
    session: 'sess:',
    user: 'user:',
    aup: 'audit:accept:terms',
    terms: 'audit:terms',
    idx: 'idx:',
  };

  // Express Request
  static readonly REQUEST_HEADER = {
    Authorization: 'Authorization',
    XRequestId: 'X-Request-Id',
    ContentType: 'ContentType',
  };

  static readonly FRENCH_MONTHS_SHORT = [
    'janv.',
    'févr.',
    'mars',
    'avr.',
    'mai',
    'juin',
    'juil.',
    'août',
    'sept.',
    'oct.',
    'nov.',
    'déc.',
  ];

  static readonly ENGLISH_MONTHS_SHORT = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  static readonly DATE_TRANSACTION_FORMAT = 'YYYY-MM-DD';
  static readonly DATE_FORMAT = 'DD MMM YYYY';
}
