<div class="ow-modal">
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      {{ 'sessionModal.page.textSessionTimeout' | translate }}
    </h5>
  </div>

  <div class="modal-body">
    <p>
      {{ 'sessionModal.page.textSessionHasExpired' | translate }}
    </p>
  </div>

  <div class="modal-footer border-top-0">
    <button (click)="signOut()" class="btn btn-primary ml-4" type="button" id="btnExpiredSignInAgain">
      {{ 'sessionModal.page.btnSignInAgain' | translate }}
    </button>
  </div>
</div>
