<div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered shared-info-modal">
    <div class="modal-content">
      <div class="modal-header d-flex flex-column">
        <div class="info-heading d-flex flex-column align-items-center mx-auto">
          <div class="d-flex flex-column align-items-center">
            <img width="48px" height="48px" class="d-lg-block d-xl-block d-xxl-block" [src]="imageSrc" alt="" />
            <h5 class="modal-title pt-1" id="confirmModalLabel" [innerHTML]="heading | translate"></h5>
            <span class="modal-sub-title fs-7" id="confirmModalSubHeading" [innerHTML]="subHeading | translate"></span>
          </div>
        </div>
      </div>

      <div class="modal-body">
        <div class="modal-body-wrapper">
          <accordion class="accordion" [closeOthers]="closeOthers" [isAnimated]="isAnimated">
            <accordion-group
              *ngFor="let accordian of accordianGroup; let index = index"
              #accGrp
              groupId="accGrp"
              [isOpen]="index === 0"
              panelClass="accordion-item panel-heading-remove-border">
              <button
                accordion-heading
                class="accordion-header btn btn-block justify-content-between d-flex w-100 shadow-none">
                <span [innerHTML]="accordian.title | translate"></span>
                <span class="float-end pull-right">
                  <i
                    class="fal"
                    [ngClass]="{ 'fa-chevron-up': accGrp?.isOpen, 'fa-chevron-down': !accGrp?.isOpen }"></i>
                </span>
              </button>
              <span tabindex="0" [innerHTML]="accordian.description | translate"></span>
            </accordion-group>
          </accordion>
        </div>
      </div>

      <div class="modal-footer d-flex">
        <button type="button" id="btnInfoModalClose" class="btn btn-outline-secondary" data-bs-dismiss="modal">
          {{ btnCloseText | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
