<div class="modal-body">
  <div class="modal-body-wrapper">
    <iframe
      id="pcoi-frame"
      title="PCOI"
      [src]="pcoiLaunchUrl | safeUrl"
      height="400px"
      width="100%"
      sandbox="allow-forms allow-scripts allow-same-origin allow-modals"></iframe>
  </div>
</div>
