import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IAuthService } from '../services';

/**
 * Guard for Angular router. Allows activating all routes. Updates users' session information every
 * time when navigating to a route.
 */
@Injectable()
export class PublicGuard implements CanActivate {
  constructor(private authService: IAuthService) {}

  /**
   * Determines if public page can be accessed. Currently returns true. Calls server's auth method to get updates
   * for authentication status.
   * @returns boolean
   */
  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.authService.auth().subscribe();
    return true;
  }
}
