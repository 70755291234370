<div class="btn-group" role="group" aria-label="Select one">
  <button
    *ngFor="let option of quickOptions"
    type="button"
    class="p-2 px-3 btn btn-outline-primary"
    [ngClass]="{ active: option.active }"
    [name]="option.name"
    [id]="option.name"
    (click)="onDateRangeSelection(option.name)">
    <span aria-hidden="true">{{ translations + option.name | translate }}</span>
    <span class="visually-hidden">{{ translations + 'aria.' + option.name | translate }}</span>
  </button>
</div>
