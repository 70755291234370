import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Subject, Subscription, auditTime } from 'rxjs';

@Component({
  selector: 'shared-heartbeat-broadcast',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedHeartbeatBroadcastComponent implements OnInit, OnDestroy {
  private _intervalMSec = 30000;
  private _heartbeatListeners = ['keydown', 'mousemove', 'touchmove'];
  private _heartbeatEventSubscription?: Subscription;
  private _heartbeatEvent$ = new Subject<UIEvent>();

  @Input() intervalMSec: number = this._intervalMSec;
  @Output() heartbeatEvent: EventEmitter<UIEvent> = new EventEmitter<UIEvent>();

  constructor(private renderer: Renderer2) {
    this._heartbeatEventSubscription = this._heartbeatEvent$.pipe(auditTime(this.intervalMSec)).subscribe((event) => {
      this.heartbeatEvent.emit(event);
    });
  }
  ngOnInit(): void {
    const body = this.renderer.selectRootElement('body', true);
    if (body) {
      this._heartbeatListeners.forEach((eventType) => {
        body['addEventListener'](
          eventType,
          ($event: UIEvent) => {
            this.sendHeartbeat.apply(this, [$event]);
          },
          false
        );
      });
    }
  }

  ngOnDestroy(): void {
    this._heartbeatEventSubscription?.unsubscribe();
  }

  private sendHeartbeat($event: UIEvent) {
    this._heartbeatEvent$.next($event);
  }
}
