import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ClientRouteURI } from '@one-access/launcher/api';
import { IFooter } from './footer.models';

/**
 * Component that displays page footer.
 */
@Component({
  selector: 'shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  @Input() hasMobilePopupFooter = false;
  public navs: IFooter[] = [];

  public ClientRouteURI = ClientRouteURI;

  ngOnInit() {
    this.navs.push({
      id: '1',
      menuitem: 'shared.button.privacy',
      url: 'shared.button.privacy_link',
      active: true,
    });

    this.navs.push({
      id: '2',
      menuitem: 'shared.button.contact',
      url: 'shared.button.contact_link',
      active: true,
    });
  }
}
