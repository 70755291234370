import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { IModalService } from './modal.interface';
import { ModalRefWithName } from './modal-ref-with-name.interface';

@Injectable()
export class ModalService implements IModalService {
  constructor(private bsModalService: BsModalService) {}

  // Keeps track of all the modal references
  private modalRefs: ModalRefWithName[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set modalRef(modalRefWithName: ModalRefWithName) {
    this.modalRefs.push(modalRefWithName);
  }

  public hideAll(): void {
    this.modalRefs.forEach((modal) => this.hide(modal.name));
  }

  public show<T>(
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: string | TemplateRef<any> | (new (...args: any[]) => T),
    config?: ModalOptions<T> | undefined
  ) {
    // It is necessary to setTimeout to avoid backdrop removed after showing the modal.
    setTimeout(() => {
      const modalRef = this.bsModalService.show(content, config);
      this.modalRef = { name: name, modalRef: modalRef };
    }, 250);
  }

  public hide(name: string): void {
    const modalIndex = this.modalRefs.findIndex((_modal) => {
      return _modal.name === name;
    });
    if (modalIndex > -1) {
      this.bsModalService.hide(this.modalRefs[modalIndex].modalRef.id);
      this.modalRefs.splice(modalIndex, 1);
    }
  }

  public hideById(id?: number | string): void {
    const modalIndex = this.modalRefs.findIndex((_modal) => {
      return _modal.modalRef.id === id;
    });
    if (modalIndex > -1) {
      this.bsModalService.hide(this.modalRefs[modalIndex].modalRef.id);
      this.modalRefs.splice(modalIndex, 1);
    }
  }

  public exists(name: string): boolean {
    const index = this.modalRefs.findIndex((modal) => {
      return modal.name === name;
    });
    return index > -1;
  }
}
