<accordion *ngIf="groups.length > 0" [closeOthers]="closeOthers" [isAnimated]="isAnimated">
  <ng-container *ngFor="let group of groups">
    <accordion-group
      [heading]="!group.useHeadingTemplate ? (group | getAccordionHeader) : ''"
      (isOpenChange)="onOpenChange($event, group)"
      [isDisabled]="!!group.isDisabled"
      [isOpen]="!!group.isOpen"
      [panelClass]="group.panelClass ? group.panelClass : 'panel-default'">
      <div
        accordion-heading
        #heading
        [id]="'heading-' + group.id"
        *ngIf="group.useHeadingTemplate"
        [innerHTML]="group | getAccordionHeader"></div>
      <ng-template
        *ngIf="!!group.contentTRef"
        [ngTemplateOutlet]="group.contentTRef"
        [ngTemplateOutletContext]="{ $implicit: this }"></ng-template>
      <ng-container *ngIf="!group.useContentTemplate">{{ group?.content }}</ng-container>
      <div *ngIf="group.useContentTemplate" [innerHTML]="group?.content"></div>
    </accordion-group>
  </ng-container>
</accordion>
