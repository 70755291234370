import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ICMSClient } from '../../services';
import { BehaviorSubject, first } from 'rxjs';
import { IResult, Patient } from '@one-access/shared/api';

@Component({
  selector: 'shared-patient-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientBannerComponent {
  @Output() changePatientEvent: EventEmitter<string> = new EventEmitter();

  private _patientSubject = new BehaviorSubject<Patient | undefined>(undefined);
  protected patient$ = this._patientSubject.asObservable();

  private _showContactInfoSubject = new BehaviorSubject<boolean>(false);
  protected showContactInfo$ = this._showContactInfoSubject.asObservable();

  constructor(cmsClient: ICMSClient) {
    cmsClient
      .currentContextPatient()
      .pipe(first())
      .subscribe((res: IResult<Patient>) => {
        this._patientSubject.next(res.data);
      });
  }

  protected toggleContactInfo() {
    this._showContactInfoSubject.next(!this._showContactInfoSubject.getValue());
  }

  protected changePatient() {
    this.changePatientEvent?.emit('clicked');
  }
}
