<div class="oh-header d-grid">
  <div class="hstack gap-3 header-container">
    <a id="linkAppTitle" class="h4 text-decoration-none h-align text-nowrap fs-5" routerLink="/" title="ONE Health">
      <span class="title text-body">{{ 'app.title' | translate }}</span>
    </a>

    <div class="facility-provider-container d-flex justify-content-end align-items-center gap-3 ms-auto">
      <div class="lang-toggle" [class.circle-icon]="isMobile">
        <a
          href="#"
          id="linkLangEn"
          class="lang en text-body text-decoration-none"
          *ngIf="!this.isMobile || this.currentLang === 'fr'"
          [class.fw-bold]="this.currentLang === 'en'"
          (click)="onClickLanguageToggle('en')"
          >EN</a
        >
        <span class="d-none d-lg-inline"> | </span>
        <a
          href="#"
          id="linkLangFr"
          class="lang fr text-body text-decoration-none"
          *ngIf="!this.isMobile || this.currentLang === 'en'"
          [class.fw-bold]="this.currentLang === 'fr'"
          (click)="onClickLanguageToggle('fr')"
          >FR</a
        >
      </div>
      <ng-container *ngIf="clinicalContext === CONTEXT_PATIENT; else provider">
        <div class="dropdown d-flex justify-content-end align-items-center" dropdown>
          <span
            class="fal fa-user circle-icon"
            id="user-settings"
            aria-controls="user-dropdown"
            dropdownToggle
            [attr.aria-label]="'shared.header.iconProfile' | translate"></span>
          <button
            class="btn btn-link dropdown-text text-body pe-none d-none d-lg-block"
            id="user-selection"
            tabindex="-1">
            {{ patientName }}
          </button>
          <ul
            *dropdownMenu
            aria-labelledBy="user-selection"
            class="dropdown-menu oh-menu-list dropdown-text text-body shadow dropdown-menu-end d-lg-none"
            id="user-dropdown"
            role="menu">
            <li role="menuitem">
              <span class="dropdown-item">{{ patientName }}</span>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-template #provider>
        <div class="dropdown d-flex justify-content-end align-items-center" dropdown>
          <div
            class="header-icon fas fa-nav-uao fs-2 text-light text-center"
            id="icon-uao"
            dropdownToggle
            aria-controls="facility-dropdown"
            [attr.aria-label]="'shared.header.iconUao' | translate">
            <span class="path1"></span><span class="path2"></span>
          </div>
          <button
            class="btn btn-link dropdown-text text-body pe-none d-none d-lg-block"
            id="facility-selection"
            tabindex="-1">
            {{ organizationName }}
          </button>
          <ul
            *dropdownMenu
            aria-labelledBy="facility-selection"
            class="dropdown-menu oh-menu-list dropdown-text text-body shadow dropdown-menu-end d-lg-none"
            id="facility-dropdown"
            role="menu">
            <li role="menuitem">
              <span class="dropdown-item">{{ organizationName }}</span>
            </li>
          </ul>
        </div>
        <div class="dropdown d-flex justify-content-end align-items-center" dropdown>
          <span
            class="fal fa-user-md circle-icon"
            id="user-settings"
            aria-controls="user-dropdown"
            dropdownToggle
            [attr.aria-label]="'shared.header.iconProfile' | translate"></span>
          <button
            class="btn btn-link dropdown-text text-body pe-none d-none d-lg-block"
            id="user-selection"
            tabindex="-1">
            {{ practitionerName }}
          </button>
          <ul
            *dropdownMenu
            aria-labelledBy="user-selection"
            class="dropdown-menu oh-menu-list dropdown-text text-body shadow dropdown-menu-end d-lg-none"
            id="user-dropdown"
            role="menu">
            <li role="menuitem">
              <span class="dropdown-item">{{ practitionerName }}</span>
            </li>
          </ul>
        </div>
      </ng-template>
    </div>
  </div>
</div>
