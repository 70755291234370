// Clinical context targets the user as either provider or patient
// PROVIDER - health care practitioner view
// PATIENT - patient view
export enum AppClinicalContext {
  PROVIDER = 'provider',
  PATIENT = 'patient',
}

// Launch intent indicates the application is embedded or standalone
// EMBEDDED - embedded (inside IFRAME) view
// STANDALONE - standalone view
export enum AppLaunchIntent {
  EMBEDDED = 'embedded',
  STANDALONE = 'standalone',
}

/**
 * Initial state properties are set query parameters from application URL.
 */
export interface IInitState {
  // Clinical context
  clinicalContext: AppClinicalContext;

  // CMS hub topic
  cmsHubTopic?: string;

  // Selected UAO (organization ID)
  selectedUAO?: string;

  // Launch intent
  launchIntent: AppLaunchIntent;
}
