import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILanguageService, IPatientService } from '../../services';
import { Patient } from '@one-access/shared/api';

/**
 * Component that displays patient card.
 */
@Component({
  selector: 'shared-patient-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientCardComponent implements OnInit {
  @Input() patientId;

  @Output() patientClick: EventEmitter<string> = new EventEmitter();

  protected isNonOntarioPatient = false;
  protected isNonHcnPatient = false;
  protected patient: Patient | undefined = undefined;

  protected currLang$;

  constructor(protected patientService: IPatientService, protected langService: ILanguageService) {
    this.patientId = '';
    this.currLang$ = langService.langSubject.asObservable();
  }

  ngOnInit(): void {
    this.patient = this.patientService.getPatientFromSearchResult(this.patientId);
    this.isNonOntarioPatient =
      this.patient?.hcn && this.patient.hcn.prov !== '' ? this.patient?.hcn.prov !== 'ON' : false;
    this.isNonHcnPatient = this.patient?.hcn.value === '';
  }

  protected viewPatient(patientId?: string) {
    if (this.patientClick && !this.isNonOntarioPatient) {
      this.patientClick.emit(patientId);
    }
  }
}
