/**
 * Maintains a list of resource types returned in FHIR responses.
 */
export enum ResourceType {
  BUNDLE = 'Bundle',
  DIAGNOSTIC_REPORT = 'DiagnosticReport',
  LOCATION = 'Location',
  OPERATION_OUTCOME = 'OperationOutcome',
  ORGANIZATION = 'Organization',
  PARAMETERS = 'Parameters',
  PATIENT = 'Patient',
  PRACTITIONER = 'Practitioner',
}
