import { Injectable } from '@angular/core';
import { IUserPreferenceService } from './user-preference.interface';
import {
  IUserPreference,
  IResult,
  ICommonRuntimeEnvironment,
  SharedServerRouteURI,
  REQUEST_HEADER,
  Status,
  UserPreferenceUpdateRequest,
} from '@one-access/shared/api';
import { IRuntimeEnvironmentService } from '../runtime-environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, first, map, take } from 'rxjs';

/**
 * Exposes functionality to save and retrieve user’s preferences.
 */
@Injectable()
export class UserPreferenceService implements IUserPreferenceService {
  private _env: ICommonRuntimeEnvironment;
  private _userPreferenceSubject = new BehaviorSubject<IUserPreference>({ doNotShowThirdPartyPopup: false });
  public userPreference$ = this._userPreferenceSubject.asObservable();

  constructor(runtimeEnv: IRuntimeEnvironmentService, private http: HttpClient) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
  }

  public get userPreference(): IUserPreference {
    return this._userPreferenceSubject.getValue();
  }

  public get(): Observable<IUserPreference> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.Preference}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp = this.http.get<IResult<IUserPreference>>(url, {
      withCredentials: true,
      observe: 'response',
      headers,
    });
    const data = resp.pipe(
      first(),
      map((resp) => {
        this._userPreferenceSubject.next(resp.body?.data ?? <IUserPreference>{});
        return resp.body?.data ?? <IUserPreference>{};
      })
    );
    return data;
  }

  public update<T = IUserPreference>(path: string, preference: T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.Preference}`;
      const headers = new HttpHeaders();
      headers.append(REQUEST_HEADER.ContentType, 'application/json');
      const payload: UserPreferenceUpdateRequest<T> = {
        path: path,
        preference: preference,
        createIfNotExists: true,
      };
      const resp = this.http.post<IResult<T>>(url, payload, {
        withCredentials: true,
        observe: 'response',
        headers,
      });
      const data = resp.pipe(
        take(1),
        map((resp) => {
          this._userPreferenceSubject.next(<IUserPreference>{ doNotShowThirdPartyPopup: preference });
          return resp.body;
        })
      );
      data.subscribe((result) => {
        if (result?.status === Status.Ok) {
          resolve((result?.data as T) ?? <T>{});
        } else {
          reject(result?.error);
        }
      });
    });
  }

  public clear(): Promise<IResult> {
    return new Promise<IResult>((resolve, reject) => {
      const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.Preference}`;
      const headers = new HttpHeaders();
      headers.append(REQUEST_HEADER.ContentType, 'application/json');
      const resp = this.http.delete<IResult>(url, {
        withCredentials: true,
        observe: 'response',
        headers,
      });
      const data = resp.pipe(
        take(1),
        map((resp) => {
          return resp.body;
        })
      );
      data.subscribe((result) => {
        if (result?.status === Status.Ok) {
          resolve(result);
        } else {
          reject();
        }
      });
    });
  }
}
