import { SharedServerRouteURI } from '@one-access/shared/api';
import { ServerRoutePath } from './server-route-path';

/**
 * Override shared route URI's and create the full URI. Individual URI's are not required at this level.
 */
export class ServerRouteURI extends SharedServerRouteURI {
  static override readonly Auth = `/${ServerRoutePath.Auth}`;
  static override readonly Auth_TTL = `/${ServerRoutePath.Auth_TTL}`;

  static override readonly Nomenclature = {
    Root: SharedServerRouteURI.Nomenclature.Root,
    MetaOLISTestRequest: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.MetaOLISTestRequest}`,
    MetaOLISTestResult: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.MetaOLISTestResult}`,
    MetaOLISTestMicro: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.MetaOLISTestMicro}`,
    MetaPhysician: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.MetaPhysician}`,
    MetaTestFacility: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.MetaTestFacility}`,
    MetaLab: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.MetaLab}`,
    MetaHospitalFacility: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.MetaHospitalFacility}`,
    MetaPCRMRNRequestEMPI: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.MetaPCRMRNRequestEMPI}`,
    MetaPCRHCNRequestEMPI: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.MetaPCRHCNRequestEMPI}`,
    SearchMetaPCRMRNRequestEMPI: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.SearchMetaPCRMRNRequestEMPI}`,
    SearchMetaOlisMRNRequestEMPI: `${this.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}${SharedServerRouteURI.Nomenclature.SearchMetaOlisMRNRequestEMPI}`,
  };

  static override readonly ONEID = {
    Root: SharedServerRouteURI.ONEID.Root,
    Login: `${SharedServerRouteURI.ONEID.Root}${SharedServerRouteURI.ONEID.Login}`,
    Redirect: `${SharedServerRouteURI.ONEID.Root}${SharedServerRouteURI.ONEID.Redirect}`,
    Logout: `${SharedServerRouteURI.ONEID.Root}${SharedServerRouteURI.ONEID.Logout}`,
    LogoutCallback: `${SharedServerRouteURI.ONEID.Root}${SharedServerRouteURI.ONEID.LogoutCallback}`,
    SSOLogin: `${SharedServerRouteURI.ONEID.Root}${SharedServerRouteURI.ONEID.SSOLogin}`,
    SSOLogout: `${SharedServerRouteURI.ONEID.Root}${SharedServerRouteURI.ONEID.SSOLogout}`,
  };

  static override readonly CMS = {
    Root: SharedServerRouteURI.CMS.Root,
    Language: `${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.Language}`,
    ConsentTarget: `${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.ConsentTarget}`,
    CurrentContext: `${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.CurrentContext}`,
    CurrentContextPatient: `${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.CurrentContextPatient}`,
    PatientClose: `${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.PatientClose}`,
    ValidateHubTopic: `${this.WebService.Root}${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.ValidateHubTopic}`,
    PatientOpen: `${this.WebService.Root}${SharedServerRouteURI.CMS.Root}${SharedServerRouteURI.CMS.PatientOpen}`,
  };

  static override readonly PCR = {
    Root: SharedServerRouteURI.PCR.Root,
    Search: `${this.WebService.Root}${SharedServerRouteURI.PCR.Root}${SharedServerRouteURI.PCR.Search}`,
  };

  static override readonly Terms = {
    Root: SharedServerRouteURI.Terms.Root,
    Accept: `${this.WebService.Root}${SharedServerRouteURI.Terms.Root}${SharedServerRouteURI.Terms.Accept}`,
    Accepted: `${this.WebService.Root}${SharedServerRouteURI.Terms.Root}${SharedServerRouteURI.Terms.Accepted}`,
  };

  static readonly Health = `/${ServerRoutePath.Health}`;

  static override readonly Error = `${SharedServerRouteURI.Error}`;

  static override readonly OLIS = {
    Root: SharedServerRouteURI.OLIS.Root,
    PatientSearch: `${this.WebService.Root}${SharedServerRouteURI.OLIS.Root}${SharedServerRouteURI.OLIS.PatientSearch}`,
  };
}
