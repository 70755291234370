import { Pipe, PipeTransform } from '@angular/core';

/**
 * Display postal code in proper format.
 */
@Pipe({
  name: 'postalCode',
})
export class PostalCodePipe implements PipeTransform {
  transform(value: string): string {
    return value
      .replace(/\W+/g, '')
      .replace(/(\w{3})(\w{3})/g, '$1 $2')
      .trim();
  }
}
