import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IAppSpinnerState, IAppSpinnerService } from './app-spinner.interface';

// Initial spinner state
const INIT_SPINNER: IAppSpinnerState = {
  isVisible: false,
};

// Debource interval
const DEBOUNCE_MSEC = 100;

/**
 * Service to provide application spinner.
 */
@Injectable({
  providedIn: 'root',
})
export class AppSpinnerService implements IAppSpinnerService {
  // Application spinner subject
  private spinnerStateSource$: BehaviorSubject<IAppSpinnerState> = new BehaviorSubject<IAppSpinnerState>(INIT_SPINNER);
  public spinnerState$: Observable<IAppSpinnerState> = this.spinnerStateSource$.asObservable();

  // Show spinner
  showSpinner(message?: string): void {
    this.updateSpinner({ ...this.spinnerStateSource$.value, isVisible: true, message });
  }

  // Hide spinner
  hideSpinner(): void {
    this.updateSpinner(INIT_SPINNER);
  }

  // Update spinner state
  // A debounce is used to coalesce overlapping updates
  private updateSpinner(spinnerState: IAppSpinnerState): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        this.spinnerStateSource$.next(spinnerState);
        resolve(true);
      }, DEBOUNCE_MSEC);
    });
  }
}
