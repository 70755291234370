import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({ selector: 'iframe[sharedResizeListen]' })
export class IFrameResizeDirective {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @HostListener('window:message', ['$event'])
  protected onMessage(e: MessageEvent) {
    if (e && e.isTrusted && !!e.data) {
      const mData = e.data;
      if (!!mData.type && mData.type.toString() == 'oh.resize') {
        this.resize(mData.data.scrollHeight);
      }
    }
  }

  private resize(height: number) {
    this.renderer.setStyle(this.el.nativeElement, 'height', height + 'px');
  }
}
