import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import { IPanelGroup, IGroup } from './accordion.models';

@Component({
  selector: 'shared-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedAccordionComponent implements OnInit, AfterViewInit {
  private _isAnimated!: boolean;
  @Input() set isAnimated(tf: boolean) {
    this._isAnimated = tf;
  }
  get isAnimated(): boolean {
    return this._isAnimated;
  }

  private _closeOthers!: boolean;
  @Input() set closeOthers(tf: boolean) {
    this._closeOthers = tf;
  }
  get closeOthers(): boolean {
    return this._closeOthers;
  }

  private _gap!: boolean;
  @Input() set gap(tf: boolean) {
    this._gap = tf;
  }
  get gap(): boolean {
    return this._gap;
  }

  private _config: IPanelGroup = {
    isAnimated: false,
    closeOthers: false,
    useHeadingTemplate: true,
    showChevron: true,
    gap: false,
  };

  @Input() set config(c: IPanelGroup) {
    this._config = c;
  }
  get config(): IPanelGroup {
    return this._config;
  }

  private _groups: IGroup[] = [];
  @Input() set groups(g: IGroup[] | null | undefined) {
    this._groups = g ?? [];
  }
  get groups(): IGroup[] {
    return this._groups;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() isOpenChange: EventEmitter<any> = new EventEmitter();
  @ViewChildren('heading') headings!: QueryList<ElementRef>;

  ngOnInit() {
    if (typeof this.closeOthers === 'undefined') {
      this.closeOthers = this.config.closeOthers || false;
    }
    if (typeof this.isAnimated === 'undefined') {
      this.isAnimated = this.config.isAnimated || false;
    }
    if (typeof this.gap === 'undefined') {
      this.gap = this.config.gap || false;
    }
  }

  ngAfterViewInit(): void {
    this.headings.changes.subscribe(() => {
      this.setChevron();
    });
    this.setChevron();
  }

  public onOpenChange(event: boolean, group: IGroup) {
    const heading = this.headings.find((heading: ElementRef) => 'heading-' + group.id === heading.nativeElement.id);
    if (group && !!group.showChevron) {
      if (event) {
        (heading?.nativeElement as HTMLElement).lastElementChild?.classList.replace('down', 'up');
        (heading?.nativeElement as HTMLElement).lastElementChild?.classList.replace('fa-chevron-down', 'fa-chevron-up');
      } else {
        (heading?.nativeElement as HTMLElement).lastElementChild?.classList.replace('up', 'down');
        (heading?.nativeElement as HTMLElement).lastElementChild?.classList.replace('fa-chevron-up', 'fa-chevron-down');
      }
    }
    this.isOpenChange.emit({ event, group });
  }

  setChevron() {
    this.headings.forEach((heading) => {
      const group: IGroup | undefined = this.groups.find(
        (group: IGroup) => 'heading-' + group.id === heading.nativeElement.id
      );
      if (group && !!group.showChevron) {
        const span = document.createElement('span');
        span.classList.add(
          'chevron',
          'fa-light',
          group.isOpen ? 'up' : 'down',
          group.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'
        );
        (heading.nativeElement as HTMLElement).appendChild(span);
      }
    });
  }
}
