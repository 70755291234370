import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ViewChildren,
  ElementRef,
  OnDestroy,
} from '@angular/core';

import {
  GridApi,
  PaginationNumberFormatterParams,
  RowDataUpdatedEvent,
  ModelUpdatedEvent,
  PaginationChangedEvent,
} from 'ag-grid-community';

/**
 * Component that displays pagination for AG-Grid.
 */
@Component({
  selector: 'shared-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnDestroy {
  @ViewChild('btFirst', { static: true }) btFirst!: ElementRef;
  @ViewChild('btPrev', { static: true }) btPrev!: ElementRef;
  @ViewChild('btNext', { static: true }) btNext!: ElementRef;
  @ViewChild('btLast', { static: true }) btLast!: ElementRef;
  @ViewChild('inCurrentPage', { static: true }) inCurrentPage!: ElementRef;
  @ViewChild('lbCurrentPage', { static: true }) lbCurrentPage!: ElementRef;
  @ViewChildren('lbTotalPages') lbTotalPages!: ElementRef[];
  @ViewChild('lbFirstDisplayedRow', { static: true }) lbFirstDisplayedRow!: ElementRef;
  @ViewChild('lbLastDisplayedRow', { static: true }) lbLastDisplayedRow!: ElementRef;
  @ViewChild('lbRowCount', { static: true }) lbRowCount!: ElementRef;

  @Input() i18nRecordsPerPage = 'Records per page:';
  @Input() i18nFromToSep = 'to';
  @Input() i18nFromToTotalSep = 'of';
  @Input() i18nPagesFromToTotalSep: string | undefined = undefined; // displays i18nFromToTotalSep if not set
  @Input() i18nRecordsFromToTotalSep: string | undefined = undefined; // displays i18nFromToTotalSep if not set
  @Input() i18nFirst = 'Go to first page';
  @Input() i18nPrev = 'Go to previous page';
  @Input() i18nNext = 'Go to next page';
  @Input() i18nLast = 'Go to last page';
  @Input() i18nPage = 'page';
  @Input() i18nPages = 'pages';
  @Input() i18nRow = 'record';
  @Input() i18nRows = 'records';

  private _slPageSize!: ElementRef;
  @ViewChild('slPageSize', { static: false }) set slPageSize(ps: ElementRef) {
    this._slPageSize = ps;
  }
  get slPageSize(): ElementRef {
    return this._slPageSize;
  }

  public hasPageSizes!: boolean;
  private _pageSizes: number[] | undefined | null;
  @Input() set pageSizes(ps: number[] | undefined | null) {
    this._pageSizes = ps;
    this.hasPageSizes = !!this._pageSizes && Array.isArray(this._pageSizes) && this._pageSizes.length > 1;
    this.cdRef.markForCheck();
  }
  get pageSizes(): number[] | undefined | null {
    return this._pageSizes;
  }

  private _grid!: GridApi;
  @Input() set gridApi(grid: GridApi) {
    if (grid) {
      if (this._grid) {
        this.onDestroyGrid();
      }
      this._grid = grid;
      if (this._grid) {
        //[pagination]="true"
        //[suppressPaginationPanel]="true"
        this.updatePagination(this._grid);
        this._grid.addEventListener('paginationChanged', (event: PaginationChangedEvent) => {
          this.updatePagination(event.api);
        });
        this._grid.addEventListener('modelUpdated', (event: ModelUpdatedEvent) => {
          this.updatePagination(event.api);
        });
        this._grid.addEventListener('rowDataUpdated', (event: RowDataUpdatedEvent) => {
          this.updatePagination(event.api);
        });
      }
    }
  }
  get gridApi(): GridApi {
    return this._grid;
  }

  public currentPageIndex!: number;
  public currentPage!: number;
  public totalPages!: number;
  public totalRows!: number;

  _pageSize!: number;
  public set pageSize(ps: number) {
    this._pageSize = ps;
  }
  get pageSize(): number {
    return this._pageSize;
  }

  public firstRow!: number;
  public lastRow!: number;

  constructor(public cdRef: ChangeDetectorRef) {}

  ngOnDestroy() {
    if (this._grid) {
      this.onDestroyGrid();
    }
  }

  onDestroyGrid() {
    this._grid.removeEventListener('paginationChanged', (event: PaginationChangedEvent) => {
      this.updatePagination(event.api);
    });
    this._grid.removeEventListener('modelUpdated', (event: ModelUpdatedEvent) => {
      this.updatePagination(event.api);
    });
    this._grid.removeEventListener('rowDataUpdated', (event: RowDataUpdatedEvent) => {
      this.updatePagination(event.api);
    });
  }

  onPageSizeChanged() {
    this.pageSize = this.slPageSize.nativeElement.value;
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  onBtGoPageNo() {
    this.gridApi.paginationGoToPage(Number(this.inCurrentPage.nativeElement.value));
  }

  onBtFirst() {
    this.gridApi.paginationGoToFirstPage();
  }

  onBtLast() {
    this.gridApi.paginationGoToLastPage();
  }

  onBtNext() {
    this.gridApi.paginationGoToNextPage();
  }

  onBtPrevious() {
    this.gridApi.paginationGoToPreviousPage();
  }

  onBtPage($event: Event) {
    if ($event && $event.target) {
      const el = $event.target as HTMLInputElement;
      if ($event.type === 'click' || $event.type === 'focus') {
        setTimeout(() => {
          el.select();
        }, 0);
      } else if ($event.type === 'keyup' || $event.type === 'keypressed') {
        const ev = $event as KeyboardEvent;

        if (ev.key === 'Tab' && ($event.type === 'keyup' || $event.type === 'keypressed')) {
          ev.preventDefault();
          return;
        } else if ($event.type === 'keypressed') {
          if (ev.key < '0' || ev.key > '9') {
            ev.preventDefault();
            el.value = this.currentPage + '';
          }
          return;
        } else if ($event.type === 'keyup') {
          if ((ev.key === 'Shift' && ev.shiftKey === false) || (ev.key === 'Control' && ev.ctrlKey === false)) {
            ev.preventDefault();
            return;
          }
        }
        const vPage = parseInt(el.value);
        if (isNaN(vPage)) {
          ev.preventDefault();
          el.value = this.currentPage + '';
        } else if (vPage > this.totalPages) {
          el.value = this.totalPages + '';
          el.blur();
        } else if (vPage === 0) {
          el.value = 1 + '';
          el.blur();
        } else if ((el.value || '').length === el.maxLength) {
          el.blur();
        } else {
          ev.preventDefault();
          return;
        }

        el.style.width = `${Math.max(0, el.value.length - 2) * 30 + 65}px`;
      } else if ($event.type === 'change') {
        if (this.inCurrentPage.nativeElement.value === '') {
          this.currentPage = 1;
        } else {
          this.currentPage = Number(this.inCurrentPage.nativeElement.value);
        }
        this.gridApi.paginationGoToPage(this.currentPage - 1);
      }
    }
  }

  pagesToMaxLength(pgC: number): number {
    if (pgC < 10) {
      return 1;
    } else if (pgC < 100) {
      return 2;
    } else if (pgC < 1000) {
      return 3;
    } else if (pgC < 10000) {
      return 4;
    } else if (pgC < 100000) {
      return 5;
    }
    return 0;
  }

  updatePagination(g: GridApi) {
    const gridAPI = g || this.gridApi;
    if (gridAPI) {
      this.totalPages = gridAPI.paginationGetTotalPages();
      this.currentPageIndex = gridAPI.paginationGetCurrentPage();
      this.currentPage = this.totalPages > 0 ? this.currentPageIndex + 1 : 0;
      this.totalRows = gridAPI.paginationGetRowCount();
      this.pageSize = gridAPI.paginationGetPageSize();
      this.firstRow = this.totalRows > 0 ? this.currentPageIndex * this.pageSize + 1 : 0;
      this.lastRow = Math.min(this.firstRow + this.pageSize - 1, this.totalRows);

      //Input
      if (this.inCurrentPage && this.inCurrentPage.nativeElement) {
        const el = this.inCurrentPage.nativeElement as HTMLInputElement;
        el.value = this.currentPage + '';
        el.maxLength = this.pagesToMaxLength(this.totalPages);
        el.minLength = 1;
      }

      this.lbCurrentPage &&
        this.lbCurrentPage.nativeElement &&
        (this.lbCurrentPage.nativeElement.innerHtml = this.currentPage);
      this.lbTotalPages &&
        this.lbTotalPages.forEach((e) => e.nativeElement && (e.nativeElement.innerHTML = this.totalPages));

      this.lbFirstDisplayedRow &&
        this.lbFirstDisplayedRow.nativeElement &&
        (this.lbFirstDisplayedRow.nativeElement.innerHTML = this.firstRow);
      this.lbLastDisplayedRow &&
        this.lbLastDisplayedRow.nativeElement &&
        (this.lbLastDisplayedRow.nativeElement.innerHTML = this.lastRow);
      this.lbRowCount && this.lbRowCount.nativeElement && (this.lbRowCount.nativeElement.innerHTML = this.totalRows);

      //Navigation
      this.toggleDisableButton(this.btFirst, this.currentPage === 1);
      this.toggleDisableButton(this.btPrev, this.currentPage === 1);
      this.toggleDisableButton(this.btNext, this.currentPage === this.totalPages);
      this.toggleDisableButton(this.btLast, this.currentPage === this.totalPages);
      this.cdRef.markForCheck();
    }
  }

  toggleDisableButton(b: ElementRef, condition = true) {
    if (b?.nativeElement) {
      b.nativeElement.disabled = condition;
    }
  }
}

export function PaginationNumberFormatter(params: PaginationNumberFormatterParams) {
  return '' + params.value.toLocaleString() + '';
}
