import { IMetaPCR_MRN_RequestEMPI, IMetaTestFacility, IResult, ITypeaheadTextSearch } from '@one-access/shared/api';
import { Observable } from 'rxjs';

export abstract class INomenclatureService {
  public abstract search_PCR_MRN_RequestEMPIs(
    payload: ITypeaheadTextSearch
  ): Observable<IResult<IMetaPCR_MRN_RequestEMPI[]>>;

  public abstract search_OLIS_MRN_RequestEMPIs(payload: ITypeaheadTextSearch): Observable<IResult<IMetaTestFacility[]>>;
}
