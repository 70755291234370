import { Injectable } from '@angular/core';
import { IErrorType } from '@one-access/shared/util';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { IErrorService } from './error.interface';
import { IModalService } from '../modal';
import { ErrorDialogComponent } from '../../error/dialog/dialog.component';

/**
 * Service that triggers error modal popup.
 */
@Injectable()
export class ErrorService implements IErrorService {
  constructor(private modalService: IModalService) {}

  openModal(errorType?: IErrorType) {
    const initialState: ModalOptions = {
      initialState: {
        errorMessage: errorType?.message ?? 'Unknown error occurred.',
        status: errorType?.id ?? '500',
      },
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
    };
    this.modalService.show(ErrorDialogComponent.name, ErrorDialogComponent, initialState);
  }

  hideModal(id: string | number | undefined): void {
    this.modalService.hideById(id);
  }
}
