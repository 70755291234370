import { TemplateRef } from '@angular/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { ModalRefWithName } from './modal-ref-with-name.interface';

export abstract class IModalService {
  public abstract set modalRef(modalRefWithName: ModalRefWithName);
  public abstract hideAll(): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public abstract show<T>(
    name: string,
    content:
      | string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | TemplateRef<any>
      | {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          new (...args: any[]): T;
        },
    config?: ModalOptions<T>
  ): void;
  public abstract hide(name: string): void;
  public abstract hideById(id?: number | string): void;
  public abstract exists(name: string): boolean;
}
