import { Injectable } from '@angular/core';
import {
  AuthProvider,
  ICommonRuntimeEnvironment,
  IUAOWithSelected,
  SharedClientRouteURI,
  SharedServerRouteURI,
} from '@one-access/shared/api';
import { IAuthService, IRuntimeEnvironmentService } from '../services';

/**
 * Shared helper functions for UI.
 */
@Injectable()
export class SharedUIUtil {
  private _env: ICommonRuntimeEnvironment;
  constructor(runtimeEnv: IRuntimeEnvironmentService, private authService: IAuthService) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
  }

  signInUrl(provider: AuthProvider): string {
    const redirectUrl = encodeURIComponent(`${this._env.appUrl}${SharedClientRouteURI.SignInCallback}/`);
    return `${this._env.apiUrl}/${provider}${SharedServerRouteURI.ONEID.Login}?redirectUrl=${redirectUrl}`;
  }

  get signOutUrl(): string {
    const signOutRedirectUrl = encodeURIComponent(`${this._env.appUrl}${SharedClientRouteURI.SignOutCallback}/`);
    return `${this._env.apiUrl}/${this.authService.lastProvider}${SharedServerRouteURI.ONEID.Logout}?redirectUrl=${signOutRedirectUrl}`;
  }

  uaoSwitchUrl(uao: IUAOWithSelected): string {
    if (uao.selected) {
      return '';
    } else {
      return this.uaoSwitchUrlById(uao.id);
    }
  }

  uaoSwitchUrlById(uao: string): string {
    const signInCallback = encodeURIComponent(`${this._env.appUrl}${SharedClientRouteURI.SignInCallback}`);
    const uaoEncoded = encodeURIComponent(uao);
    return `${this._env.apiUrl}/${this.authService.status.provider}${SharedServerRouteURI.ONEID.Login}?uao=${uaoEncoded}&redirectUrl=${signInCallback}`;
  }
}
