import { ErrorTypes, IErrorType } from './error';

export class UtilCommon {
  static httpErrorType(status: number): IErrorType {
    if (status === ErrorTypes.Http.BadRequest.id) {
      return ErrorTypes.Http.BadRequest;
    } else if (status === ErrorTypes.Http.Unauthorized.id) {
      return ErrorTypes.Http.Unauthorized;
    } else if (status === ErrorTypes.Http.NotFound.id) {
      return ErrorTypes.Http.NotFound;
    } else {
      return ErrorTypes.Http.Error;
    }
  }

  /** Calculate age based on date of birth */
  static calculateAge(birthDate: string | number | Date): number {
    const dob = new Date(birthDate);
    const ageDifMs = Date.now() - dob.getTime();
    const ageDate = new Date(ageDifMs); // Milliseconds from epoch.
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  static padString = (pad: string, str: string, padLeft: boolean) => {
    if (typeof str === 'undefined') {
      return pad;
    }
    if (padLeft) {
      return (pad + str).slice(-pad.length);
    } else {
      return (str + pad).substring(0, pad.length);
    }
  };

  static stringOrDefault = (strValue: string | undefined, defaultValue = ''): string => {
    return strValue ?? defaultValue;
  };

  static removeAllQueryParams(url: string) {
    try {
      const updatedUrl = url.split('?');
      return `${updatedUrl[0]}`;
    } catch {
      return url;
    }
  }
}
