import { inject, Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const I18N_VALUES: any = {
  fr: {
    weekdays: ['lu', 'ma', 'me', 'je', 've', 'sa', 'di'],
    months: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'juil', 'aou', 'sep', 'oct', 'nov', 'déc'],
    weekLabel: 'sem',
  },
  en: {
    weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    weekLabel: 'Week',
  },
};

@Injectable()
export class I18n {
  language!: string;
  constructor(private translateService: TranslateService) {
    this.language = this.translateService.currentLang;
  }
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  private _i18n = inject(I18n);

  override getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this._i18n.language]?.weekdays[weekday - 1];
  }
  override getWeekLabel(): string {
    return I18N_VALUES[this._i18n.language]?.weekLabel;
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language]?.months[month - 1];
  }
  override getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  override getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
