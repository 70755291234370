import { Component, Input, ViewEncapsulation, ViewChild } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomFormControl } from '../../helpers/custom-form-control';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n } from '../custom-date-picker-I18n';

@Component({
  selector: 'shared-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatepickerComponent,
    },
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class DatepickerComponent extends CustomFormControl {
  @Input() minDate!: NgbDateStruct;
  @Input() maxDate!: NgbDateStruct;

  @ViewChild('dp', { static: false }) dp!: BsDatepickerDirective;
}
