export abstract class SharedServerRoutePath {
  static readonly ApiRoute = {
    Root: 'api',
  };

  static readonly WebService = {
    Root: 'ws',
  };

  static readonly Auth = 'auth';
  static readonly Auth_TTL = 'auth-ttl';

  static readonly Nomenclature = {
    Root: 'nomenclature',
    MetaOLISTestRequest: 'meta-olis-test-request',
    MetaOLISTestResult: 'meta-olis-test-result',
    MetaOLISTestMicro: 'meta-olis-test-micro',
    MetaPhysician: 'meta-physician',
    MetaTestFacility: 'meta-test-facility',
    MetaLab: 'meta-lab',
    MetaHospitalFacility: 'meta-hospital-facility',
    MetaPCRMRNRequestEMPI: 'meta-pcr-mrn-request-empi',
    MetaPCRHCNRequestEMPI: 'meta-pcr-hcn-request-empi',
    SearchMetaPCRMRNRequestEMPI: 'search-meta-pcr-mrn-request-empi',
    SearchMetaOlisMRNRequestEMPI: 'search-meta-olis-mrn-request-empi',
  };

  static readonly ONEID = {
    Root: 'oneid',
    Login: 'login',
    Redirect: 'redirect',
    Logout: 'logout',
    LogoutCallback: 'callback',
    SSOLogin: 'sso-login',
    SSOLogout: 'sso-logout',
  };

  static readonly CMS = {
    Root: 'cms',
    CurrentContext: 'current-context',
    CurrentContextPatient: 'current-context-patient',
    Language: 'language',
    ConsentTarget: 'consent-target',
    PatientClose: 'patient-close',
    ValidateHubTopic: 'validateHubTopic',
    PatientOpen: 'patient-open',
  };

  static readonly PCR = {
    Root: 'pcr',
    Search: 'search',
  };

  static readonly SmileDHDR = {
    Root: 'smile-dhdr',
    Search: 'search',
  };

  static readonly Terms = {
    Root: 'terms',
    Accept: 'accept',
    Accepted: 'accepted',
  };

  static readonly Error = 'error';

  static readonly OLIS = {
    Root: 'olis',
    PatientSearch: 'patient-search',
  };

  static readonly Preference = 'preference';
}
