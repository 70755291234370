import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * Displays simple OK message if successfully launched.
 * Used for health check of front-end application.
 */
@Component({
  selector: 'shared-health',
  template: `OK`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthComponent {}
