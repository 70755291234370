import { ActivatedRouteSnapshot, NavigationExtras, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IAuthResponse } from '@one-access/shared/api';

export interface IRedirectionExtras extends NavigationExtras {
  target?: string;
}

export abstract class IRedirectService {
  public abstract window: (Window & typeof globalThis) | null;
  public abstract jump(url: string, target: string): Promise<boolean>;
  public abstract external(url: string): boolean;
  public abstract redirect(url: string, extras?: IRedirectionExtras): Promise<boolean>;
  public abstract navigate(url: string, extras?: IRedirectionExtras): Promise<boolean>;
  public abstract navigateWithContext(
    url: string,
    isInternalApp: boolean,
    authContext: IAuthResponse,
    extras?: IRedirectionExtras
  ): Promise<boolean>;
  public abstract canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>;
}
