import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplacement',
})
export class StringReplacementPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: string, ...args: any[]): unknown {
    const data = args[0];
    let subStrings = (value || '').split(/({{.*?}})/);
    if (subStrings.length > 1) {
      subStrings = subStrings.map((str) => {
        const stringReducer = str.replace(/(^{{\s*)|(\s*}}$)/g, '');
        if (data[stringReducer]) {
          return data[stringReducer];
        }
        return stringReducer;
      });
    }
    return subStrings.join('');
  }
}
