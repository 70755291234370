import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IFooter } from '../models/footer.model';

@Component({
  selector: 'shared-footer-thin',
  templateUrl: './footer-thin.component.html',
  styleUrls: ['./footer-thin.component.scss'],
})
export class FooterThinComponent {
  // App state
  @Input() isMobile = false;

  // Footer navigation
  navs: IFooter[] = [];

  constructor(private translateService: TranslateService) {
    this.translateService.get('shared.footer.links').subscribe({
      next: (links) => {
        links?.forEach((link: { menuitem: string; url: string }) => {
          this.navs.push({
            menuitem: link.menuitem,
            url: link.url,
          });
        });
      },
    });
  }
}
