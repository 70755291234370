import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Validation from '../../validators/date-validation';
import dayjs from 'dayjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'shared-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements OnInit {
  @Input() quickDateRangeLabel!: string;
  @Input() dateRangeLabel!: string;
  @Input() toLabel!: string;
  @Input() startDateLabel!: string;
  @Input() endDateLabel!: string;

  @Input() viewMode = 'desktop';

  // Errors
  @Input() startDateRequiredError = '';
  @Input() startDateInvalidFormatError = '';
  @Input() startMinDateError = '';
  @Input() startMaxDateError = '';

  @Input() endDateRequiredError = '';
  @Input() endDateInvalidFormatError = '';
  @Input() endMinDateError = '';
  @Input() endMaxDateError = '';

  @Input() invalidStartDateRangeError = '';
  @Input() invalidEndDateRangeError = '';

  // Buttons
  @Input() searchButtonText = '';
  @Input() cancelButtonText = '';
  @Input() okButtonText = '';

  @Input() quickOptions: { name: string; active: boolean }[] = [
    { name: '1W', active: false },
    { name: '1M', active: false },
    { name: '3M', active: true },
    { name: '6M', active: false },
    { name: '1Y', active: false },
  ];
  @Input() quickDateRangeTranslations = '';
  private _selectedOption = '3M';
  @Input() set selectedOption(selectedOpt: string) {
    this._selectedOption = selectedOpt;
    this.setQuickOption(selectedOpt);
  }
  get selectedOption(): string {
    return this._selectedOption;
  }

  @Output() submitPeriodFilter: EventEmitter<{
    startDate: NgbDateStruct;
    endDate: NgbDateStruct;
  }> = new EventEmitter();

  showDateRangeForm = false;
  dateRangePickerForm!: FormGroup;
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  dateRange!: string;

  undoPeriod!: { startDate: NgbDateStruct; endDate: NgbDateStruct };

  constructor(private _fb: FormBuilder) {}

  dateConverter(date: Date): NgbDateStruct {
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  ngOnInit(): void {
    this.minDate = this.dateConverter(dayjs().startOf('day').subtract(100, 'years').toDate());
    this.maxDate = this.dateConverter(dayjs().startOf('day').toDate());

    let startDate: NgbDateStruct = this.getStartDate(this.selectedOption);
    if (sessionStorage.getItem('startDate')) {
      startDate = this.dateConverter(dayjs(sessionStorage.getItem('startDate')).toDate());
    }

    let endDate: NgbDateStruct = this.maxDate;
    if (sessionStorage.getItem('endDate')) {
      endDate = this.dateConverter(dayjs(sessionStorage.getItem('endDate')).toDate());
    }

    this.dateRangePickerForm = this._fb.group(
      {
        startDate: this._fb.control<NgbDateStruct>(startDate, {
          nonNullable: true,
          validators: [Validators.required, Validation.isValidDate()],
        }),
        endDate: this._fb.control<NgbDateStruct>(endDate, {
          nonNullable: true,
          validators: [Validators.required, Validation.isValidDate()],
        }),
      },
      {
        validators: [Validation.isValidRange('startDate', 'endDate')],
      }
    );

    this.setQuickOption(this.selectedOption);
  }

  getStartDate(dateRangeVal: string): NgbDateStruct {
    let date: Date = new Date();
    switch (dateRangeVal) {
      case '1W':
        date = dayjs().startOf('day').subtract(1, 'weeks').toDate();
        break;
      case '1M':
        date = dayjs().startOf('day').subtract(1, 'months').toDate();
        break;
      case '3M':
        date = dayjs().startOf('day').subtract(3, 'months').toDate();
        break;
      case '6M':
        date = dayjs().startOf('day').subtract(6, 'months').toDate();
        break;
      case '1Y':
        date = dayjs().startOf('day').subtract(1, 'years').toDate();
        break;
    }
    return this.dateConverter(date);
  }

  getDate(date: NgbDateStruct) {
    return new Date(date.year, date.month - 1, date.day);
  }

  setDateRange() {
    const startDate = this.dateRangePickerForm.controls['startDate'].value
      ? dayjs(this.getDate(this.dateRangePickerForm.controls['startDate'].value)).format('DD MMM YYYY')
      : '---';

    const endDate = this.dateRangePickerForm.controls['endDate'].value
      ? dayjs(this.getDate(this.dateRangePickerForm.controls['endDate'].value)).format('DD MMM YYYY')
      : '---';
    this.dateRange = `${startDate} - ${endDate}`;
    this.undoPeriod = {
      startDate: this.dateRangePickerForm.controls['startDate'].value,
      endDate: this.dateRangePickerForm.controls['endDate'].value,
    };
  }

  onSubmit() {
    this.submitPeriodFilter.emit({
      startDate: this.dateRangePickerForm.controls['startDate'].value,
      endDate: this.dateRangePickerForm.controls['endDate'].value,
    });
    this.setDateRange();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setQuickOption(name: any) {
    if (this.dateRangePickerForm) {
      const date = this.getStartDate(name);
      this.dateRangePickerForm.controls['startDate'].patchValue(date);

      const today = this.dateConverter(dayjs().startOf('day').toDate());
      this.dateRangePickerForm.controls['endDate'].patchValue(today);

      this.dateRangePickerForm.controls['startDate'].markAsDirty();
      this.dateRangePickerForm.controls['startDate'].markAsTouched();
      this.dateRangePickerForm.controls['endDate'].markAsDirty();
      this.dateRangePickerForm.controls['endDate'].markAsTouched();

      this.quickOptions.forEach((o) => (o.active = o.name === name));

      this.setDateRange();

      this.submitPeriodFilter.emit({
        startDate: this.dateRangePickerForm.controls['startDate'].value,
        endDate: this.dateRangePickerForm.controls['endDate'].value,
      });
    }
  }

  cancelFilter() {
    this.dateRangePickerForm.controls['startDate'].patchValue(this.undoPeriod.startDate);
    this.dateRangePickerForm.controls['endDate'].patchValue(this.undoPeriod.endDate);
    this.closePeriodFilter();
  }

  togglePeriodFilter() {
    this.showDateRangeForm = !this.showDateRangeForm;
  }

  closePeriodFilter() {
    this.showDateRangeForm = false;
  }
}
