import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';

/**
 * Extension of TranslateLoader that loads language files using http client.
 */
export class TranslateBrowserLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getTranslation(lang: string): Observable<any> {
    return new TranslateHttpLoader(this.http).getTranslation(lang);
  }
}

/**
 * Factory method to initialize TranslateBrowserLoader.
 * @param httpClient
 * @returns an instance of TranslateBrowserLoader
 */
export function translateBrowserLoaderFactory(httpClient: HttpClient) {
  return new TranslateBrowserLoader(httpClient);
}
