import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IErrorService } from '../../services/error';
import { IAuthService } from '../../services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Clipboard } from '@angular/cdk/clipboard';

/**
 * Component that displays an error in a modal popup. Displays response code that identifies the error for the support
 * team. User can dismiss and try the action again.
 */
@Component({
  selector: 'shared-error-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ErrorDialogComponent implements OnInit {
  protected showDetail = false;
  private errorMessage = '';
  private status: unknown;
  protected fullMessage: string | undefined;

  constructor(
    private errorService: IErrorService,
    private authService: IAuthService,
    protected modalRef: BsModalRef,
    private clipboard: Clipboard
  ) {}

  ngOnInit() {
    this.fullMessage = `RC${this.status}-${this.authService.status.sessionId?.slice(-8) ?? '0'}: ${this.errorMessage}`;
  }

  hideModal(): void {
    this.errorService.hideModal(this.modalRef.id);
  }

  copyToClipboard(data: string) {
    this.clipboard.copy(data);
  }
}
