import { Pipe, PipeTransform } from '@angular/core';

/**
 * Display phone number in proper format.
 */
@Pipe({
  name: 'phone',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (value !== null && value !== undefined && value !== '') {
      return value
        .replace(/\s+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3')
        .trim();
    } else return '';
  }
}
