import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IAuthResponse,
  ICommonRuntimeEnvironment,
  IUAO,
  IUAOWithSelected,
  SharedClientRouteData,
  SharedClientRouteURI,
} from '@one-access/shared/api';
import { Observable, Subscription } from 'rxjs';
import { SharedUIUtil } from '../../helpers';
import { IAuthService, ILanguageService, IRedirectService, IRuntimeEnvironmentService } from '../../services';

/**
 * Component that displays page header.
 */
@Component({
  selector: 'shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy {
  protected authStatus$: Observable<IAuthResponse>;
  private authStatusSub: Subscription;
  protected fullName = '';
  protected currentUAOObj$: Observable<IUAO | undefined>;
  protected uaosWithSelected$: Observable<IUAOWithSelected[]>;
  protected fullMenu = false;
  protected hideSignInLink = false;

  private _env: ICommonRuntimeEnvironment;
  protected feedbackUrl: string;
  protected SharedClientRouteURI = SharedClientRouteURI;
  protected currLang$;

  constructor(
    private authService: IAuthService,
    private langService: ILanguageService,
    protected sharedUIUtil: SharedUIUtil,
    route: ActivatedRoute,
    runtimeEnv: IRuntimeEnvironmentService,
    private redirectService: IRedirectService
  ) {
    this.currLang$ = langService.langSubject.asObservable();
    this.authStatus$ = this.authService.status$;
    this.authStatusSub = this.authStatus$.subscribe((status) => {
      this.fullName = `${status.givenName} ${status.familyName}`;
    });
    this.currentUAOObj$ = this.authService.currentUAOObj$;
    this.uaosWithSelected$ = this.authService.uaosWithSelected$;
    this.hideSignInLink = route.snapshot.data[SharedClientRouteData.HideSignInLink] ?? false;
    this.fullMenu = route.snapshot.data[SharedClientRouteData.FullHeaderMenu] ?? false;

    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
    this.feedbackUrl = this._env.feedbackUrl;
  }
  ngOnDestroy(): void {
    this.authStatusSub.unsubscribe();
  }

  protected uaoSelected() {
    this.authService.UAOSwitchInProgress = true;
  }

  protected switchLanguage() {
    this.langService.switchLanguage();
  }

  protected redirectToSignOut() {
    this.redirectService.navigate(this.sharedUIUtil.signOutUrl, { target: '_self' });
  }
}
