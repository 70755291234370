import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InfoModalComponent } from './info-modal.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild({ extend: true }), AccordionModule.forRoot()],
  declarations: [InfoModalComponent],
  exports: [InfoModalComponent],
})
export class SharedInfoModalModule {}
