<div
  class="card shadow-sm pt-3 patient-card mb-3"
  [ngClass]="{
    clickable: !isNonOntarioPatient,
    'has-footer-alert': isNonOntarioPatient,
    'card-warning': isNonOntarioPatient
  }"
  (click)="viewPatient(patient.id)"
  *ngIf="patient">
  <div class="d-flex flex-column flex-md-row mb-3">
    <!-- Patient Info -->
    <div class="flex-shrink-0">
      <div class="d-flex">
        <div class="flex-shrink-0 px-2 ms-1 align-self-md-center text-end">
          <div class="flex-column avatar">
            <div
              class="fas fa-profile-avatar text-primary"
              [attr.aria-label]="'workspace.common.labelName' | translate"
              tabindex="0">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 pe-md-4">
          <label class="clickable mb-0">
            {{ patient.familyName }}, {{ patient.givenName }} {{ patient.middleName }}</label
          >
          <div class="d-flex">
            <div class="fs-sm">
              {{ 'shared.messages.gender.' + (patient.gender | lowercase) | translate }} |
              {{ 'shared.messages.age' | translate: { age: patient.age } }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vertical-row opacity-25 d-none d-md-block">&nbsp;</div>

    <!-- Health Card -->
    <div class="flex-shrink-1 sm-indent pt-1">
      <div class="d-flex">
        <div class="flex-shrink-0 ps-md-4">
          <span
            class="fa-light fa-id-card fa-fw text-primary"
            [ngClass]="{
              'text-gray900': isNonOntarioPatient,
              'text-primary': !isNonOntarioPatient
            }"
            [attr.aria-label]="'workspace.searchResults.labelHCN' | translate"
            tabindex="0"></span>
        </div>

        <div class="flex-shrink-1 align-self-center ps-2 pe-4">
          <div class="fs-sm fw-bold d-none d-md-block" *ngIf="patient.hcn.value !== ''; else mrnLabel">
            {{ 'workspace.searchResults.labelHCN' | translate }}
          </div>
          <ng-template #mrnLabel>
            <div class="fs-sm fw-bold d-none d-md-block" *ngIf="patient.mrn !== ''">
              {{ 'workspace.searchResults.labelMRN' | translate }}
            </div>
          </ng-template>
          <div class="fs-sm">
            <span class="fs-sm" *ngIf="patient.hcn.value !== ''; else mrnValue">
              {{ patient.hcn.value | hcn }} ({{ patient.hcn.prov
              }}<span class="d-inline-block d-md-none">&nbsp;{{ 'workspace.searchResults.labelHCN' | translate }}</span
              >)</span
            >
            <ng-template #mrnValue>
              <span class="fs-sm" *ngIf="patient.mrn !== ''">
                {{ patient.mrn }}
                <span class="d-inline-block d-md-none">({{ 'workspace.searchResults.labelMRN' | translate }})</span>
              </span>
            </ng-template>
            <span class="fs-sm" *ngIf="patient.mrn === '' && patient.hcn.value === ''">{{
              'workspace.searchResults.missingHCNNumber' | translate
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="opacity-25 d-none d-md-block">&nbsp;</div>

    <!-- DOB -->
    <div class="flex-shrink-1 sm-indent pt-1">
      <div class="d-flex">
        <div class="flex-shrink-0 ps-md-4">
          <span
            class="fal fa-calendar-alt fa-fw text-primary"
            [attr.aria-label]="'workspace.searchResults.labelDOB' | translate"
            tabindex="0"></span>
        </div>

        <div class="flex-shrink-1 align-self-center ps-2 pe-4">
          <div class="fs-sm fw-bold d-none d-md-block">
            {{ 'workspace.searchResults.labelDOB' | translate }}
          </div>
          <div class="fs-sm">
            {{ patient.dob | localizedDate }}
          </div>
        </div>
      </div>
    </div>

    <div class="opacity-25 d-none d-md-block" *ngIf="patient.address">&nbsp;</div>

    <!-- Address -->
    <div class="flex-shrink-1 sm-indent pt-1" *ngIf="patient.address">
      <div class="d-flex">
        <div class="flex-shrink-0 ps-md-4">
          <span
            class="fal fa-map-marker-alt fa-fw text-primary"
            [attr.aria-label]="'workspace.searchResults.labelContactInfo' | translate"
            tabindex="0"></span>
        </div>

        <div class="flex-shrink-1 align-self-center ps-2 pe-4">
          <div class="fs-sm fw-bold d-none d-md-block">
            {{ 'workspace.searchResults.labelContactInfo' | translate }}
          </div>
          <div class="fs-sm">
            {{
              (patient.address.line1 ? patient.address.line1 : '') +
                (patient.address.line2 ? ' ' + patient.address.line2 : '')
            }}
          </div>
          <div class="fs-sm">
            {{ patient.address.city }}{{ patient.address.province ? ', ' + patient.address.province : '' }}
            {{ patient.address.postalCode | postalCode }}
          </div>
          <div class="fs-sm">
            {{ patient.homePhone | phone }}
          </div>
          <div class="fs-sm">
            {{ patient.email }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer alert for non-ontario patient-->
  <div *ngIf="isNonOntarioPatient" class="border-top-3 card-footer is-footer-alert card-footer-warning">
    <div class="body">
      <div class="icon-container">
        <span
          aria-hidden="true"
          role="presentation"
          class="icon text-center fal fa-exclamation-triangle text-black"
          data-cls-calc--alert-icon="fal fa-exclamation-triangle text-black"
          [attr.aria-label]="'workspace.searchResults.hasMissingHCN' | translate"
          tabindex="0"></span>
      </div>
      <div>
        <div class="content d-flex">{{ 'workspace.searchResults.hasMissingHCN' | translate }}</div>
      </div>
    </div>
  </div>
  <!-- Footer alert for non-hcn patient-->
  <div *ngIf="isNonHcnPatient" class="border-top-3 card-footer is-footer-alert card-footer-warning bg-info-light">
    <div class="d-flex">
      <div class="icon-container">
        <span
          aria-hidden="true"
          role="presentation"
          class="icon text-center fa-light fa-circle-exclamation text-primary"
          data-cls-calc--alert-icon="fa-light fa-circle-exclamation text-primary"
          [attr.aria-label]="'workspace.searchResults.hasNoHCN' | translate"
          tabindex="0"></span>
      </div>
      <div>
        <div class="content d-flex">{{ 'workspace.searchResults.hasNoHCN' | translate }}</div>
      </div>
    </div>
  </div>
</div>
