import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-pcoi-modal-content',
  templateUrl: './pcoi-modal-content.component.html',
  styleUrls: ['./pcoi-modal-content.component.scss'],
})
export class PcoiModalContentComponent {
  @Input() pcoiLaunchUrl = '';
}
