import { Observable } from 'rxjs';

// Spinner state
export interface IAppSpinnerState {
  isVisible: boolean;
  message?: string;
}

// Spinner service
export abstract class IAppSpinnerService {
  public abstract spinnerState$: Observable<IAppSpinnerState>;
  public abstract showSpinner(message?: string): void;
  public abstract hideSpinner(): void;
}
