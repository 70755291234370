import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IRedirectionExtras, IRedirectService } from './redirect.interface';
import { IAuthResponse } from '@one-access/shared/api';

/**
 * Allows angular application to redirect the user without directly using the window.location functionality.
 */
@Injectable()
export class RedirectService implements IRedirectService, CanActivate {
  constructor(readonly router: Router, @Inject(DOCUMENT) readonly document: Document) {}

  /** The Window object from Document defaultView */
  public get window(): (Window & typeof globalThis) | null {
    return this.document.defaultView;
  }

  /** Jumps instantly to the external link without the mediation of the router */
  public jump(url: string, target: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        resolve(!!this.window?.open(url, target));
      } catch (e) {
        reject(e);
      }
    });
  }

  /** Returns true if the given url looks external */
  public external(url: string): boolean {
    return /^http(?:s)?:\/{2}\S+$/.test(url);
  }

  /** Redirects to the specified external link with the mediation of the router */
  public redirect(url: string, extras?: IRedirectionExtras | undefined): Promise<boolean> {
    // Extracts the target from the extras
    const target = extras && extras.target;
    // Compose the url link for redirection
    const link = '/redirect?url=' + encodeURIComponent(url) + (target ? '&target=' + target : '');
    // Navigates with the router activat the redirection guard
    return this.router.navigateByUrl(link, extras);
  }

  /** Navigates to the given url, redirecting when necessary
   * @param url An absolute URL. The function does not apply any delta to the current URL.
   * When starting with 'http(s)://' triggers the external redirection.
   * @param extras (optional). An object containing properties that modify the navigation strategy.
   * The function ignores any properties that would change the provided URL.
   */
  public navigate(url: string, extras?: IRedirectionExtras | undefined): Promise<boolean> {
    return this.external(url)
      ? // Redirects to external link
        this.redirect(url, extras)
      : // Navigates with the router otherwise
        this.router.navigateByUrl(url, extras);
  }

  /** Navigates to the given url and includes authentication context as query parameters.
   * @param url An absolute URL. The function does not apply any delta to the current URL.
   * When starting with 'http(s)://' triggers the external redirection.
   * @param authContext The authentication context object.
   * @param extras (optional). An object containing properties that modify the navigation strategy.
   * The function ignores any properties that would change the provided URL.
   */
  public navigateWithContext(
    url: string,
    isInternalApp: boolean,
    authContext: IAuthResponse,
    extras?: IRedirectionExtras | undefined
  ): Promise<boolean> {
    // The application catalog URL contains substitution parameters which
    // are optionally provided by the authentication context.
    const hubTopicId = authContext?.hubTopicId;
    const uaoId = authContext?.currentUAO;
    if (hubTopicId) url = url.replace(/{{hubTopicId}}/gi, encodeURIComponent(`${hubTopicId}`));
    if (uaoId) url = isInternalApp ? url.replace(/{{uaoId}}/gi, encodeURIComponent(`${uaoId}`)) : url;
    return this.navigate(url, extras);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // Gets the url query parameter, if any
    const url = route.queryParamMap.get('url') ?? '';
    // If the url matches an external link, redirects stopping the route activation
    if (this.external(url)) {
      // Gets the optional target, when specified
      const target = route.queryParamMap.get('target');
      // Jumps to the external resource
      return this.jump(url, target ?? '_blank').then(() => false);
    } else if (url.startsWith('/')) {
      return this.jump(url, '_self').then(() => false);
    }
    // Goes on activating the requested route, most likely to NotFound
    return true;
  }
}
