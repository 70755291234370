import { NgModule } from '@angular/core';
import { QuickDateRangeComponent } from './quick-date-range/quick-date-range.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { FormControlPipe } from '../pipes';
import { NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateFormatter } from './custom-date-formatter';

@NgModule({
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    TranslateModule.forChild({ extend: true }),
    ReactiveFormsModule,
    NgbDatepickerModule,
  ],
  declarations: [QuickDateRangeComponent, DatepickerComponent, DateRangePickerComponent, FormControlPipe],
  exports: [QuickDateRangeComponent, DatepickerComponent, DateRangePickerComponent, FormControlPipe],
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateFormatter }],
})
export class SharedDatePickerModule {}
