import { Component, OnInit, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  IAppContextResponse,
  IOrganizationContext,
  IPatientContext,
  IPractitionerContext,
} from '@one-access/shared/api';
import { Observable } from 'rxjs';
import { AppClinicalContext, AppLaunchIntent } from '../models';

@Component({
  selector: 'shared-header-thin',
  templateUrl: './header-thin.component.html',
  styleUrls: ['./header-thin.component.scss'],
})
export class HeaderThinComponent implements OnInit {
  @Input() clinicalContext: AppClinicalContext = AppClinicalContext.PROVIDER;
  @Input() currentAppContext$!: Observable<IAppContextResponse>;
  @Input() isMobile = false;

  readonly CONTEXT_PATIENT = AppClinicalContext.PATIENT;
  readonly INTENT_STANDALONE = AppLaunchIntent.STANDALONE;

  // current language
  currentLang!: string;

  // Organization/patient/practitioner context
  organizationName!: string;
  patientName!: string;
  practitionerName!: string;

  constructor(private translateService: TranslateService) {
    // set current language
    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.currentAppContext$.subscribe((appContext) => {
      // Format organization
      if (appContext?.organization) {
        const organization: IOrganizationContext = appContext.organization;
        let fullName = '';
        if (organization.name) fullName += organization.name;
        this.organizationName = fullName;
      }

      if (appContext?.patient) {
        const patient: IPatientContext = appContext.patient;
        let name = '';
        if (patient.prefix) name = `${patient.prefix} `;
        if (patient.firstName) name += `${patient.firstName} `;
        if (patient.lastName) name += patient.lastName;
        this.patientName = name;
      }

      // Format practitioner
      if (appContext?.practitioner) {
        const practitioner: IPractitionerContext = appContext.practitioner;
        let fullName = '';
        if (practitioner.prefix) fullName = `${practitioner.prefix} `;
        if (practitioner.firstName) fullName += `${practitioner.firstName} `;
        if (practitioner.lastName) fullName += `${practitioner.lastName}`;
        this.practitionerName = fullName;
      }
    });
  }

  onClickLanguageToggle(lang: string) {
    // update language
    localStorage.setItem('lang', lang);
    location.reload();
    return false;
  }
}
