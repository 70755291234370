<div>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ 'errors.heading' | translate }}
    </h4>
    <button
      (click)="hideModal()"
      aria-label="Close"
      class="btn-close close pull-right"
      type="button"
      id="btnDialogClose">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>{{ 'errors.sub' | translate }}</p>

    <p>
      {{ 'errors.description' | translate }} <a class="link-primary-dark">{{ 'errors.descriptionLink' | translate }}</a>
      {{ 'errors.description2' | translate }}
    </p>
    <div class="mb-3">
      <a (click)="showDetail = !showDetail" class="link-primary-dark" id="linkDialogShowDetail">
        {{ showDetail === true ? ('errors.labelHide' | translate) : ('errors.labelShow' | translate) }}
        <span class="text-decoration-none" [ngClass]="showDetail ? 'fal fa-chevron-up' : 'fal fa-chevron-down'"></span>
      </a>

      <div class="my-3" [collapse]="!showDetail" [isAnimated]="true">
        <ul>
          <li [innerHTML]="'errors.textHelp1' | translate"></li>
          <li>{{ 'errors.textHelp2' | translate }}</li>
          <li [innerHTML]="'errors.textHelp3' | translate"></li>
        </ul>
      </div>

      <div class="error-detail my-3 p-3 fs-xm">
        {{ fullMessage }}
        <div class="mt-4 text-end">
          <p>
            <a
              class="link link-primary-dark"
              href="javascript:void(0)"
              (click)="copyToClipboard(fullMessage ?? '')"
              id="linkDialogCopyDetails">
              {{ 'errors.copyDetails' | translate }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="hideModal()" class="btn btn-primary" type="button" id="btnDialogOk">
      {{ 'shared.button.ok' | translate }}
    </button>
  </div>
</div>
