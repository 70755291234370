import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ITermsService } from './terms.interface';
import {
  ICommonRuntimeEnvironment,
  IResult,
  REQUEST_HEADER,
  SharedClientRouteURI,
  SharedServerRouteURI,
  Status,
  Terms,
  TermsAccepted,
  TermsData,
} from '@one-access/shared/api';
import { IRuntimeEnvironmentService } from '../runtime-environment';
import { ICatalogueService } from '../catalogue';
import { Observable, of } from 'rxjs';
import { ErrorTypes } from '@one-access/shared/util';
import { IRedirectService } from '../redirect';

/**
 * Service that allows to retrieve terms.json file from external resource and determines what terms to display to the
 * user depending on user’s entitlements. Also, exposes functionality communicate with the backend API services to save
 * the user’s acceptance of the terms and check if user has accepted the terms.
 */
@Injectable()
export class TermsService implements ITermsService {
  private _env: ICommonRuntimeEnvironment;
  private _terms?: Terms;
  private _applicableTerms?: TermsData[];
  private _acceptedTerms?: TermsAccepted[];
  protected termsUrl: string;
  protected catalogueUrl: string;

  constructor(
    private http: HttpClient,
    runtimeEnv: IRuntimeEnvironmentService,
    private catalogueService: ICatalogueService,
    private redirect: IRedirectService
  ) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
    this.termsUrl = this._env.termsUrl;
    this.catalogueUrl = this._env.catalogueUrl;
  }

  public accept(payload: TermsData[]): Observable<IResult<boolean>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.Terms.Root}${SharedServerRouteURI.Terms.Accept}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    this._acceptedTerms = undefined;
    const resp = this.http.post<IResult<boolean>>(url, payload, {
      withCredentials: true,
      observe: 'response',
      headers,
    });
    const data = resp.pipe(
      map((resp) => {
        return resp.body ?? { status: Status.Error };
      })
    );
    return data;
  }

  public accepted(payload: TermsData[]): Observable<TermsAccepted[]> {
    if (this._acceptedTerms) {
      return of(this._acceptedTerms);
    }

    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.Terms.Root}${SharedServerRouteURI.Terms.Accepted}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp = this.http.post<IResult<TermsAccepted[]>>(url, payload, {
      withCredentials: true,
      observe: 'response',
      headers,
    });
    return resp.pipe(
      map((resp) => {
        this._acceptedTerms = resp.body?.data;
        return this._acceptedTerms ?? [];
      })
    );
  }

  /**
   * Gets a list of terms that are applicable to the user.
   * @returns An observable that emits a list of terms that are applicable to the user
   */
  public getApplicableTerms(): Observable<TermsData[]> {
    if (this._applicableTerms) {
      return of(this._applicableTerms);
    }

    return this.getContent().pipe(
      switchMap(() => {
        return this.catalogueService.getEntitledApps().pipe(
          map((appsData) => {
            const termsForApps: string[] = ['one_health']; // one_health terms are always required to access the website.
            appsData.forEach((appData) => {
              appData.termIds.forEach((termsId) => {
                if (termsForApps.findIndex((x) => x.toLowerCase() === termsId.toLowerCase()) === -1) {
                  termsForApps.push(termsId);
                }
              });
            });
            this._applicableTerms = this.filteredTerms(termsForApps);
            return this._applicableTerms;
          })
        );
      })
    );
  }

  /**
   * Retrieve terms.json file
   * @returns A promise to return after Terms data is retrieved.
   */
  private getContent(): Observable<Terms> {
    if (this._terms) {
      return of(this._terms);
    }
    return this.http.get<Terms>(this.termsUrl).pipe(
      catchError(() => {
        this.redirect.redirect(`${SharedClientRouteURI.Error}?rc=${ErrorTypes.Terms.GetContentError.id}`);
        return [];
      }),
      map((res) => {
        this._terms = res;
        return this._terms;
      })
    );
  }

  /**
   * Get terms for each term Id in the request.
   * @param termIds Array of terms Ids
   * @returns A list of terms for each terms Id
   */
  private filteredTerms(termIds: string[]): TermsData[] {
    const filteredTerms = this._terms?.terms.filter((term) => termIds.some((item) => item === term.termsId));
    return filteredTerms ?? [];
  }
}
