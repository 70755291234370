/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ChangeDetectionStrategy, ElementRef, OnInit, OnDestroy, Input } from '@angular/core';
import { SharedIFrameResizeBroadcastService } from './iframe-resize.service';
import { IFrameMessage } from './iframe-message.interface';

let nextSharedBroadcastComponentId = 0;

/**
 * This component should be added to app.component.html
 */
@Component({
  selector: 'shared-iframe-resize-broadcast',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedIFrameContentResizeComponent implements OnInit, OnDestroy {
  private _id = `shared-iframe-broadcast-${nextSharedBroadcastComponentId++}`;
  set id(s: string) {
    this._id = s;
  }
  get id(): string {
    return this._id;
  }

  private _body: HTMLBodyElement | null = null;
  set body(b: HTMLBodyElement | null) {
    if (!b) {
      this.setDocumentBody();
    } else {
      this._body = b;
    }
  }
  get body(): HTMLBodyElement | null {
    if (!this._body) {
      this.body = null;
    }
    return this._body;
  }

  // additional query selectors to watch for size changes.
  @Input() watchQuerySelectors: string[] = [];

  setDocumentBody(sel = 'body') {
    this._body = document.querySelector(sel);
  }

  private _iframe: HTMLIFrameElement[] | null = null;
  set iframe(f: HTMLIFrameElement[] | HTMLIFrameElement | null) {
    if (!f) {
      this._iframe = null;
    } else {
      this._iframe = Array.isArray(f) ? f : [f];
    }
  }
  get iframe(): HTMLIFrameElement[] | null {
    return this._iframe;
  }

  constructor(private el: ElementRef, private broadcastService: SharedIFrameResizeBroadcastService) {}

  ngOnInit() {
    const el = this.el.nativeElement;
    el.setAttribute('role', 'presentation');
    el.setAttribute('aria-hidden', 'true');
    el.id = el.id || this.id;

    this.broadcastService.addComponent(this, 'postResizeMessage');

    const observeDOM = (function () {
      const MutationObserver = window.MutationObserver; //|| window.WebKitMutationObserver;

      return function (obj: any, options: MutationObserverInit | undefined, callback: any) {
        if (!obj || obj.nodeType !== 1) return;

        if (MutationObserver) {
          // define a new observer
          const mutationObserver = new MutationObserver(callback);

          // have the observer observe foo for changes in children
          mutationObserver.observe(obj, options);
          return mutationObserver;
        }

        // browser support fallback
        else if (typeof window.addEventListener !== 'undefined') {
          obj.addEventListener('DOMNodeInserted', callback, false);
          obj.addEventListener('DOMNodeRemoved', callback, false);
        }
        return;
      };
    })();

    observeDOM(this.body, { childList: true, subtree: true }, (data: any) => {
      for (let i = 0; i < data[0].addedNodes.length; i++) {
        this.postResizeMessage();

        const querySelectors: string[] = ['textarea'].concat(this.watchQuerySelectors);
        if (
          !!data[0].addedNodes[i].querySelectorAll &&
          data[0].addedNodes[i].querySelectorAll(querySelectors.join(','))
        ) {
          querySelectors?.forEach((querySelector: string) => {
            const el = document.querySelector(querySelector);

            if (el) {
              observeDOM(el, { attributes: true, attributeFilter: ['style'] }, () => {
                this.postResizeMessage();
              });
            }
          });
        }
      }
    });
  }

  postMessage(message: IFrameMessage, postTo: 'owner' | 'iframe' = 'owner'): void {
    // window.top refers to parent window
    if (postTo === 'owner') {
      if (window && window.top && this.inIframe()) {
        window.top.postMessage(message, `*`);
      }
    } else if (postTo === 'iframe') {
      if (!!this.iframe && Array.isArray(this.iframe)) {
        for (let iframeI = 0, iframeL = this.iframe.length; iframeI < iframeL; iframeI++) {
          const iframe = this.iframe[iframeI];
          if (!!iframe && !!iframe.contentWindow && !!iframe.contentWindow.postMessage) {
            const iFrameUrl = new URL(iframe.src);
            iframe.contentWindow.postMessage(message, `${iFrameUrl.protocol}//${iFrameUrl.host}`);
          }
        }
      }
    }
  }

  postResizeMessage() {
    this.postMessage({
      type: 'oh.resize',
      data: {
        scrollHeight: document.body.scrollHeight,
        scrollWidth: document.body.scrollWidth,
        origin: location.origin,
      },
    });
  }

  inIframe(el?: Window | ElementRef) {
    if (typeof el === 'undefined') {
      el = window;
      if (!el) {
        return false;
      }
    }
    if (el instanceof ElementRef) {
      if (!el.nativeElement) {
        return false;
      }

      if (el.nativeElement.ownerDocument) {
        const d = el.nativeElement.ownerDocument;
        el = d.defaultView ? d.defaultView : d.parentWindow;
      }
    }
    if (el) {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.broadcastService.removeComponent(this);
  }
}
