import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ITypeaheadTextSearch,
  IMetaPCR_MRN_RequestEMPI,
  ICommonRuntimeEnvironment,
  REQUEST_HEADER,
  IResult,
  SharedServerRouteURI,
  IMetaTestFacility,
} from '@one-access/shared/api';
import { map, Observable } from 'rxjs';
import { INomenclatureService } from './nomenclature.interface';
import { IRuntimeEnvironmentService } from '../runtime-environment';

/**
 * Defines functionality to retrieve nomenclature data from REDIS.
 */
@Injectable()
export class NomenclatureService implements INomenclatureService {
  private _env: ICommonRuntimeEnvironment;

  constructor(runtimeEnv: IRuntimeEnvironmentService, private http: HttpClient) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
  }

  public search_PCR_MRN_RequestEMPIs(payload: ITypeaheadTextSearch): Observable<IResult<IMetaPCR_MRN_RequestEMPI[]>> {
    const url =
      `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}` +
      `${SharedServerRouteURI.Nomenclature.SearchMetaPCRMRNRequestEMPI}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp$ = this.http.post(url, payload, { withCredentials: true, observe: 'response', headers });
    const data$ = resp$.pipe(
      map((x) => {
        return <IResult<IMetaPCR_MRN_RequestEMPI[]>>x.body;
      })
    );
    return data$;
  }

  public search_OLIS_MRN_RequestEMPIs(payload: ITypeaheadTextSearch): Observable<IResult<IMetaTestFacility[]>> {
    const url =
      `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.Nomenclature.Root}` +
      `${SharedServerRouteURI.Nomenclature.SearchMetaOlisMRNRequestEMPI}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp$ = this.http.post(url, payload, { withCredentials: true, observe: 'response', headers });
    const data$ = resp$.pipe(
      map((x) => {
        return <IResult<IMetaTestFacility[]>>x.body;
      })
    );
    return data$;
  }
}
