import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthService, IRedirectService } from '../../services';
import { SharedUIUtil } from '../../helpers';

/**
 * Component that displays user session is about to expire and displays a countdown until expiry. User has the option
 * to extend the current session or sign out.
 */
@Component({
  selector: 'shared-session-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionReminderComponent {
  timerData$: Observable<{ min: number; sec: number }>;

  constructor(
    private redirect: IRedirectService,
    private sharedUIUtil: SharedUIUtil,
    private authService: IAuthService
  ) {
    this.timerData$ = authService.reminderTimer$;
  }

  extendSessionSelected(): void {
    this.authService.extendSession();
  }

  signOutSelected(): void {
    this.redirect.navigate(this.sharedUIUtil.signOutUrl, { target: '_self' });
  }
}
