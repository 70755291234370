import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ICommonRuntimeEnvironment,
  IResult,
  OLIS,
  Patient,
  REQUEST_HEADER,
  SharedServerRouteURI,
} from '@one-access/shared/api';
import { map, Observable } from 'rxjs';
import { IOLISService } from './olis.interface';
import { IRuntimeEnvironmentService } from '../runtime-environment';

/**
 * Service that allows communication with the backend API service to search and retrieve patient information from OLIS.
 */
@Injectable()
export class OlisService implements IOLISService {
  private _env: ICommonRuntimeEnvironment;

  constructor(private http: HttpClient, runtimeEnv: IRuntimeEnvironmentService) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
  }

  public patientSearch(payload: OLIS.IPatientSearchData): Observable<IResult<Patient[]>> {
    const url = `${this._env.apiUrl}${SharedServerRouteURI.WebService.Root}${SharedServerRouteURI.OLIS.Root}${SharedServerRouteURI.OLIS.PatientSearch}`;
    const headers = new HttpHeaders();
    headers.append(REQUEST_HEADER.ContentType, 'application/json');
    const resp = this.http.post(url, payload, { withCredentials: true, observe: 'response', headers });
    const data = resp.pipe(
      map((x) => {
        return <IResult<Patient[]>>x.body;
      })
    );
    return data;
  }
}
