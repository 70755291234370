import { IAuthResponse, IUAO, IUAOWithSelected } from '@one-access/shared/api';
import { Observable } from 'rxjs';

export abstract class IAuthService {
  public abstract lastProvider: string;
  public abstract readonly status$: Observable<IAuthResponse>;
  public abstract status: IAuthResponse;
  public abstract readonly currentUAOObj$: Observable<IUAO | undefined>;
  public abstract currentUAOObj: IUAO | undefined;
  public abstract readonly uaosWithSelected$: Observable<IUAOWithSelected[]>;
  public abstract uaosWithSelected: IUAOWithSelected[];
  public abstract readonly authTTL$: Observable<number>;
  public abstract authTTL: number;
  public abstract readonly reminderTimer$: Observable<{ min: number; sec: number }>;
  public abstract UAOSwitchInProgress: boolean;
  public abstract auth(): Observable<IAuthResponse>;
  public abstract getAuthTTL(id: string): void;
  public abstract cancelAuthTTL(): void;
  public abstract extendSession(): void;
  public abstract setReminderTimer(min: number, sec: number): void;
  public abstract getReminderTimer(): { min: number; sec: number };
}
