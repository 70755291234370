import { SharedClientRoutePath } from './shared-client-route-path';

export abstract class SharedClientRouteURI {
  static readonly Home = '/';
  static readonly Error = `/${SharedClientRoutePath.Error}`;
  static readonly SignIn = `/${SharedClientRoutePath.SignIn}`;
  static readonly SignInUAOs = `/${SharedClientRoutePath.SignIn}/${SharedClientRoutePath.SignInUAOs}`;
  static readonly SignInTerms = `/${SharedClientRoutePath.SignIn}/${SharedClientRoutePath.SignInTerms}`;
  static readonly SignInCallback = `/${SharedClientRoutePath.SignIn}/${SharedClientRoutePath.SignInCallback}`;
  static readonly SignOut = `/${SharedClientRoutePath.SignOut}`;
  static readonly SignOutCallback = `/${SharedClientRoutePath.SignOut}/${SharedClientRoutePath.SignOutCallback}`;
}
