import { NgModule } from '@angular/core';
import { IRedirectService, RedirectService } from '../services';
import { RedirectWithHrefDirective } from './redirect-with-href.directive';
import { RedirectDirective } from './redirect.directive';

/**
 * Exports the required providers and directives to redirect the user.
 */
@NgModule({
  imports: [],
  declarations: [RedirectDirective, RedirectWithHrefDirective],
  exports: [RedirectDirective, RedirectWithHrefDirective],
  providers: [{ provide: IRedirectService, useClass: RedirectService }],
})
export class RedirectModule {}
