<div *ngIf="isVisible$ | async" class="app-spinner-overlay">
  <div class="app-spinner-body">
    <div class="app-spinner" role="status">
      <img src="/assets/images/spinner-third.svg" alt="Spinner icon" id="imgAppSpinner" />
    </div>
    <div class="app-spinner-text">
      {{ spinner.message }}
    </div>
  </div>
</div>
