/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';
import { SharedConstant } from '@one-access/shared/util';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export default class Validation {
  static isValidRange(startDate: string, endDate: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const from = controls.get(startDate);
      const to = controls.get(endDate);
      const momentTo = dayjs(new Date(to?.value?.year, to?.value?.month - 1, to?.value?.day));
      const momentFrom = dayjs(new Date(from?.value?.year, from?.value?.month - 1, from?.value?.day));
      const val = momentFrom > momentTo ? { isValidRange: true } : null;
      return val;
    };
  }

  static isValidDate(): ValidatorFn {
    return (control: AbstractControl) => {
      const dt = control.value;
      if (!dt) return null;
      const val = !(
        dt &&
        dayjs(
          dayjs(new Date(dt.year, dt.month - 1, dt.day)).format(SharedConstant.DATE_FORMAT),
          SharedConstant.DATE_FORMAT,
          true
        ).isValid()
      )
        ? { isValidDate: true }
        : null;
      return val;
    };
  }
}
